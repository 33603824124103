import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';
import { UsuarioService } from 'src/app/services/services.index';
import { ArticulosService } from 'src/app/services/services.index';
import { Articulo } from '../../../models/articulo';
import { ProveedoresService } from 'src/app/services/services.index';
import { Router} from '@angular/router';
import { CargaImagenService } from 'src/app/services/services.index';
import Swal from 'sweetalert2';
import { Category } from '../../../models/category';
import { CategoriesService } from '../../../services/categories/categories.service';
import { Establishment } from '../../../models/establishment';
import { EstablishmentsService } from '../../../services/establishments/establishments.service';
import { Stock } from 'src/app/models/stock';
import { PresupuestosService } from 'src/app/services/presupuestos/presupuestos.service';
import { Order } from 'src/app/models/order';
import { DatePipe } from '@angular/common';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { Cliente } from 'src/app/models/clientes';
import { MatTableDataSource } from '@angular/material/table';


@Component ({
  selector: 'cards-articulos-detalle',
  templateUrl: './cards-articulos-detalle.component.html',
  styleUrls: ['./cards-articulos-detalle.component.css'],
  providers: [DatePipe]
})

export class CardsArticulosDetalleComponent implements OnInit {

  codArti: number;
  statusCodProv: string;
  proveedores: any;
  selectedOpcionCodProv: any = null;
  categories: string;
  cantidad: number = 1;
  index: number;
  //selectedOpcionCodCat: any = null;
  imgFile = null;
  imagenCargada = false;
  dolar: number;
  // Datos usuario
  token: string;
  identity: any;
  order: any;
  establishment_id: number;
  cliente: Cliente;
  flagpresupuesto: any;

  // Articulo obtenido api
  articulo: Articulo;
  status: boolean = false;
  costofinal: number;
  artdataSource: any[] = [];
  artArray: any[] = [];
  auxArray: any[] = [];
  selectedArt: any;

  // Arreglos características
  
  ancho: any[] = [];
  alto: any[] = [];
  largo: any[] = [];
  espesor: any[] = [];
  litros: any[] = [];
  metros: any[] = [];
  peso: any[] = [];
  color: any[] = [];

  // Caracteristicas seleccionadas
 
  selectedAncho: any;
  selectedAlto: any;
  selectedLargo: any;
  selectedEspesor: any;
  selectedLitros: any;
  selectedMetros: any;
  selectedPeso: any;
  selectedColor: any;




  // Establecimientos
  establishments: Establishment[];

  //  Stock
  auxStock: Stock[] = [];

  // Bandera
  dataReady: boolean = false;
  dataSourceSearchOrder: any;
  paginator: any;
  sort: any;
  auxOrder: any;

  constructor(private activatedRoute: ActivatedRoute, private _usuarioService: UsuarioService,
              private articuloService: ArticulosService, private proveedoresService: ProveedoresService,
              private cargaImagenService: CargaImagenService, private router: Router,
              private EstablishmentsService: EstablishmentsService, private categoriesService: CategoriesService, 
              private presupuestosService: PresupuestosService, private datepipe: DatePipe,
              private clientesService: ClientesService,) {
    
    this.order = new Order(null, null, null, null, [], null, null, null, null);
    this.order.fecha = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    this.order.dateTime = this.datepipe.transform(new Date(), 'yyyy-MM-ddThh:mm:ss');
    this.order.user_id = this._usuarioService.getIdentidad().sub;
    this.token = this._usuarioService.getToken();
    this.establishment_id = _usuarioService.getIdentidad().establi;

    this.identity = this._usuarioService.getIdentidad();
    //console.log(this.identity.sub);

    this.clientesService.getUserIDClients(this.token, this.identity.sub).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.cliente = Response.data[0];
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
    });

    this.articuloService.getCotiDolar(this.token).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.dolar = Response.dolar;
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
      });

    this.proveedoresService.obtenerCodProv(this.token).subscribe(
      Response => {
        this.statusCodProv = Response.status;
        if (this.statusCodProv === 'success') {
          this.proveedores = Response.Proveedores;
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
      });

    
    this.activatedRoute.params.subscribe(
      params => {
        this.codArti = params['codArticulo'];
          this.getArticulo(params['codArticulo']);
          this.dataReady = true;
      });


  }

  ngOnInit() {
  }

  getEstablishments() {
    this.EstablishmentsService.getEstablishments(this.token).subscribe(
      Response => {
        //console.log('Response: ', Response);
        if (Response.status === 'success') {
          this.establishments = Response.data;
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
      });
  }

  getEstablishmentsName(id: number) {
    let resp: string = '';
    if (this.establishments) {
      this.establishments.forEach(element => {
          if (element.id === id) {
             resp = element.name;
          }
      });
    }
    return resp;
  }

  getArticulo(codArticulo: number) {
      this.articuloService.getArticulo(this.token, codArticulo).subscribe(
        Response => {
          if (Response.status === 'success') {
            // Asigno Articulo obtenido
            this.articulo = Response.articulo;
            //console.log('el articulo wey:',this.articulo);
            this.status = true;
            this.costofinal=this.getFinalPrice(this.articulo.ganancia, this.articulo.impuesto, this.articulo.costo, this.articulo.flete, this.articulo.costodlls);
            this.getEstablishments();
            //console.log(this.articulo.category);
            this.selectedAlto = this.articulo.alto;
            this.selectedAncho = this.articulo.ancho;
            this.selectedLargo = this.articulo.largo;
            this.selectedEspesor = this.articulo.espesor;
            this.selectedMetros = this.articulo.metros;
            this.selectedLitros = this.articulo.litros;
            this.selectedPeso = this.articulo.peso;
            this.selectedColor = this.articulo.color;
            this.articuloService.getArtCat(this.articulo.category, this.token).subscribe(
              Response => {
                if (Response.status === 'success') {
                  this.artdataSource = Response.articulos;
                  //console.log('artcat:',Response.articulos);
                }
              },
              error => {
                console.log('error: ', error);
              });
            if(this.articulo.main===1){
              this.articuloService.getArtRel(this.articulo.descripcion, this.token).subscribe(
                Response => {
                  if (Response.status === 'success') {
                    this.artArray = Response.articulos;
                    this.auxArray = Response.articulos;
                    this.setArrays(this.artArray);
                  }
                },
                error => {
                  console.log('error: ', error);
                });
            }
            else{
              this.artArray=[];
              //console.log('deberia estar vacio',this.artArray);
              //console.log('viene por ruta el arreglo');
              this.activatedRoute.queryParams.subscribe(
                params =>{
                  this.artArray= JSON.parse(params['articulos']);  
                });
              //console.log('recibido',this.artArray);
              this.setArrays(this.artArray);
            }
          } else {
            Swal.fire('Error', Response.message, 'error');
          }
          
        },
        error => {
          console.log(error);
          Swal.fire('Error', '!Oops Algo salio mal', 'error');
        });
  }

  OnSubmit() {
  }

   // obtengo los datos de la imagen subida y hago una pre-visualizacion
   cargandoImagen(files: FileList) {

    this.imgFile = files[0];
    let reader = new FileReader();
    reader.onload =  (e) => {
        this.imagenCargada = true;
    };
    reader.readAsDataURL(files[0]);
  }

  getFinalPrice(ganancia, impuesto, costo, flete, costodlls=null): number {
    if (this.articulo.ganancia != null && (this.articulo.costo != null || this.articulo.costodlls != null) && this.articulo.impuesto != null && this.articulo.flete != null) {
      if(costodlls){
        costo = costodlls * this.dolar;
      }
      let x1 = parseFloat(ganancia);
      let x2 = parseFloat(costo);
      let x3 = parseFloat(flete);
      let x4 = parseFloat(impuesto);

      let co =((x4* x2) / 100) + ((x3 * x2) / 100) + x2;
 
      return ((x1 * co) / 100)  + co;
    } else {
      return 0;
    }
}

  setear(dolares){
    this.articulo.costo = dolares * this.dolar;
  }

  addToCart(){
    this._usuarioService.getUser(this.identity.sub, this.token).subscribe(
      Response => {
        this.flagpresupuesto = Response.user.id_presupuesto;
        //console.log("presupuesto:",this.flagpresupuesto);
        //Tengo un presupuesto asociado - Añado artículos
        if(this.flagpresupuesto != null ){
          //console.log('Hay presupuesto asignado');
          this.presupuestosService.getPresupuesto(this.token, this.flagpresupuesto).subscribe(
            Response => {
              if (Response.status === 'success') {
                this.auxOrder = Response.order;
                this.dataSourceSearchOrder = new MatTableDataSource(this.auxOrder);
                this.dataSourceSearchOrder.paginator = this.paginator;
                this.dataSourceSearchOrder.sort = this.sort;
                this.appendPresupuestoLine(this.dataSourceSearchOrder.data);
              } 
            },
            error => {
              console.log(error);
            });  

        }
        //No tengo un presupuesto asociado - Lo creo y cargo artículos al carro
        else{
          //console.log('No hay presupuesto asignado');
          this.generarPresupuesto();
        }    
      },
      error => {
        console.log(error);
      });
  }


  generarPresupuesto(){
    //si se quiere manejar precio contado eliminar (this.costofinal*1.3) -- debe quedar this.costofinal
    this.order.id_cliente = this.cliente.codCliente;
    this.order.total = ((this.costofinal*1.3)*this.cantidad);
    this.order.discount =  0;
    this.order.clientDiscount = 0;
    this.order.discountC = 0;
    this.order.discountR = 0;
    this.order.rechargeCC = 0;
    this.order.discountCant = 0;
    this.order.discountCards = 0;
    this.order.establishment_id = 1;
    this.order.selectedClient = this.cliente;
    this.order.orderLine.push(this.articulo);
    this.order.orderLine[0].unitPrice = (this.costofinal*1.3);
    this.order.orderLine[0].id_articulo = this.articulo.codArticulo;
    this.order.orderLine[0].cantidad = this.cantidad;
    this.order.orderLine[0].total = (this.costofinal*1.3) * this.cantidad;        
    //console.log('order:',this.order);

    this.presupuestosService.armarPresupuestosinPDF(this.token, this.order).subscribe(
    Response => {
             console.log(Response);
             if (Response.status === 'success') {
                this.identity.idpresup = Response.idPres;
                this._usuarioService.addPresup(this.identity.sub, this.identity.idpresup, this.token).subscribe(
                  Response => {
                    console.log(Response);
                  },
                  error => {
                    console.log(error);
                  });

                Swal.fire({
                  position: 'top-end',
                  type: 'success',
                  title: 'El artículo fue añadido a su carro de compras',
                  showConfirmButton: false,
                  timer: 1500
                });
             }
    },
    error =>{
      console.log(error);
      Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
    });
  }


  appendPresupuestoLine(presupuesto){
    //console.log('lalala',presupuesto);
    //this.index = presupuesto.orderLines.length ;
    //si se quiere manejar precio contado eliminar (this.costofinal*1.3) -- debe quedar this.costofinal
    this.order.orderLine.push(this.articulo);
    this.order.orderLine[0].unitPrice = (this.costofinal*1.3);
    this.order.orderLine[0].id_articulo = this.articulo.codArticulo;
    this.order.orderLine[0].cantidad = this.cantidad;
    this.order.orderLine[0].total = (this.costofinal*1.3) * this.cantidad; 
    //console.log('llerolero',this.order);
    this.presupuestosService.addOrderLine(this.token, presupuesto.id, this.order.orderLine).subscribe(
    Response => {
             console.log(Response);
             if (Response.status === 'success') {
                
                Swal.fire({
                  position: 'top-end',
                  type: 'success',
                  title: 'El artículo fue añadido a su carro de compras',
                  showConfirmButton: false,
                  timer: 1500
                });
             }
    },
    error =>{
      console.log(error);
      Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
    });
  }


  //Funciones Slider

  slideConfig = {
    "slidesToShow": 6,
    "slidesToScroll": 4,
    "infinite": false
  };

  slickInit(e:any) {
    console.log('slick initialized');
  }

  breakpoint(e:any) {
    console.log('breakpoint');
  }

  afterChange(e:any) {
    console.log('afterChange');
  }

  beforeChange(e:any) {
    console.log('beforeChange');
  }


  scrollUp()
  {  
    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 80); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  changeArt()
  {  
    //console.log('test this: ',this.articulo);
    let params = {
      'articulos':JSON.stringify(this.artArray)
    }
    this.router.navigate(['/pages/cards-articulos-detalle', this.articulo.codArticulo], {queryParams: params});
  }


  selectAlto(){
    this.articulo = this.artArray.find(x => x.alto == this.selectedAlto);
    let auxArray = this.artArray.filter(x => x.largo == this.selectedLargo);
    this.artArray = auxArray;
    this.changeArt();
  }

  selectAncho(){
    this.articulo = this.artArray.find(x => x.ancho == this.selectedAncho);
    let auxArray = this.artArray.filter(x => x.ancho == this.selectedAncho);
    this.artArray = auxArray;
    this.changeArt();
  }

  selectLargo(){
    this.articulo = this.artArray.find(x => x.largo == this.selectedLargo);
    let auxArray = this.artArray.filter(x => x.largo == this.selectedLargo);
    this.artArray = auxArray;
    this.changeArt();
  }

  selectEspesor(){
    this.articulo = this.artArray.find(x => x.espesor == this.selectedEspesor);
    let auxArray = this.artArray.filter(x => x.espesor == this.selectedEspesor);
    this.artArray = auxArray;
    this.changeArt();
  }

  selectLitros(){
    this.articulo = this.artArray.find(x => x.litros == this.selectedLitros);
    let auxArray = this.artArray.filter(x => x.litros == this.selectedLitros);
    this.artArray = auxArray;
    this.changeArt(); 
  }

  selectMetros(){
    this.articulo = this.artArray.find(x => x.metros == this.selectedMetros);
    let auxArray = this.artArray.filter(x => x.metros == this.selectedMetros);
    this.artArray = auxArray;
    this.changeArt();
  }

  selectPeso(){
    this.articulo = this.artArray.find(x => x.peso == this.selectedPeso);
    let auxArray = this.artArray.filter(x => x.peso == this.selectedPeso);
    this.artArray = auxArray;
    this.changeArt();
  }

  selectColor(){
    this.articulo = this.artArray.find(x => x.color == this.selectedColor);
    let auxArray = this.artArray.filter(x => x.color == this.selectedColor);
    this.artArray = auxArray;
    this.changeArt();
  }

  setArrays(array : any){
      this.ancho = [];
      this.alto = [];
      this.largo = [];
      this.espesor = [];
      this.litros = [];
      this.metros = [];
      this.peso = [];
      this.color = [];
      array.forEach(element => {
        if(element.ancho!=null && element.ancho!='')
        {
          if(!this.ancho.includes(element.ancho))
            this.ancho.push(element.ancho);
        }
        if(element.alto!=null && element.alto!='')
        {
          if(!this.alto.includes(element.alto))
            this.alto.push(element.alto);
        }
        if(element.largo!=null && element.largo!='')
        {
          if(!this.largo.includes(element.largo))
            this.largo.push(element.largo);
        }
        if(element.espesor!=null  && element.espesor!='')
        {
          if(!this.espesor.includes(element.espesor))
            this.espesor.push(element.espesor);
        }
        if(element.metros!=null  && element.metros!='')
        {
          if(!this.metros.includes(element.metros))
            this.metros.push(element.metros);
        }
        if(element.litros!=null  && element.litros!='')
        {
          if(!this.litros.includes(element.litros))
            this.litros.push(element.litros);
        }
        if(element.peso!=null  && element.peso!='')
        {
          if(!this.peso.includes(element.peso))
            this.peso.push(element.peso);
        }
        if(element.color!=null  && element.color!='')
        { 
          if(!this.color.includes(element.color))
            this.color.push(element.color);
        }
      });
      // console.log('anchos',this.ancho);
      // console.log('altos',this.alto);
      // console.log('largos',this.largo);
      // console.log('espesor',this.espesor);
      // console.log('litros',this.litros);
      // console.log('metros',this.metros);
      // console.log('colores',this.color);
  }


}
