import { Component, OnInit } from '@angular/core';
// Otros
import { Router } from '@angular/router';
import { DialogChangepwComponent } from './dialog-changepw/dialog-changepw';

// Modelos
import { Usuario } from '../models/usuario';

// Servicios
import { UsuarioService } from '../services/usuario/usuario.service';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material/dialog';
// import { Intercom } from 'ng-intercom';
import { ClientesService } from '../services/clientes/clientes.service';


declare function init_plugins();

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.css'],
  providers: [UsuarioService, MatDialog]
})
export class LoginComponent implements OnInit {

  status: string;
  usuario: Usuario;
  token: string;
  identidad: any;

  constructor(
    //public intercom: Intercom,
    public router:  Router,
    private _usuarioService: UsuarioService,
    private _clientsService: ClientesService,
    public dialog: MatDialog,
    ) {
    this.usuario = new Usuario(1, '', '', '', '', '', 1);
  }

  ngOnInit() {
    init_plugins();
  }

  ingresar() {
      this._usuarioService.login(this.usuario).subscribe(
       response => {

          // Obtengo token
          if (response.status === 'ok') {
            this.token = response.token;
            localStorage.setItem('token', this.token);
            localStorage.setItem('menu', JSON.stringify(response.menu));
            localStorage.setItem('establishment_id', response.establishment_id);
            this.status = response.status;

            // Obtengo Usuario
            this._usuarioService.login(this.usuario, true).subscribe(
              response => {
                // Obtengo Usuario
                this.identidad = response.usuario;
                localStorage.setItem('identidad', JSON.stringify(this.identidad));
                
                // this.intercom.boot({
                //   app_id: "h609sw2a",
                //   name: this.identidad.nya, // Full name
                // email: this.identidad.email, // Email address   
                //   // Supports all optional configuration.
                //   widget: {
                //     "activator": "#intercom" 
                //   }
                // });
                if(this.identidad.rol == 'clientedc'){
                this._usuarioService.ingresodc(this.identidad.sub,this.token).subscribe(
                  Response=>{
                    console.log(Response);
                  },
                  error=>{
                    console.log(error);
                  }
                );
              }else if(this.identidad.rol == 'vendedor'){

                // if(this.identidad.sub == 6){
                this._clientsService.getClientsDepo(this.token).subscribe(
                Response => {
                  if (Response.status === 'success') {
                    localStorage.setItem('clientes', JSON.stringify(Response.data));
                  } else {
                    console.log(Response.message);
                  }
                },
                error => {
                  console.log('error: ', error);
              });
            // }
            // else{
            //   this._clientsService.getSellerClients(this.token, this.identidad.sub).subscribe(
            //     Response => {
            //       if (Response.status === 'success') {
            //         localStorage.setItem('clientes', JSON.stringify(Response.data));
            //       } else {
            //         console.log(Response.message);
            //       }
            //     },
            //     error => {
            //       console.log('error: ', error);
            //   });
            // }
            }        
              
                // ingreso al sistema
                this.router.navigate(['pages/cards-articulos']);
              },
              error => {
                this.status = error;
                console.log(error);

              }
            );
            } else {
              this.status = response.status;
            }

        },
        error => {
          this.status = error;
          console.log(error);

        }
      );
    //
  }

  modifyPass() {
    let dialog = this.dialog.open(DialogChangepwComponent, {
      disableClose: true,
      width: '35%',
      height: '28%',
      data: {
        //realAmount: 
      }
    });

     // Cuando se cierra el dialog
     dialog.afterClosed().subscribe(result => {
      if (result) {

      }
     });
  }

}
