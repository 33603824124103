import { Component, OnInit, Input, ViewChild, ElementRef } from '@angular/core';
import {FormControl} from '@angular/forms';
import { Cliente } from '../../models/clientes';
import {COMMA, ENTER} from '@angular/cdk/keycodes';
import { MatChipInputEvent } from '@angular/material/chips/typings/chip-input';
import { Observable} from 'rxjs';
import { startWith, map } from 'rxjs/operators';
import { MatAutocomplete, MatAutocompleteSelectedEvent } from '@angular/material/autocomplete';

@Component({
  selector: 'app-autocomplete-chips',
  templateUrl: './autocomplete-chips.component.html',
  styles: []
})
export class AutocompleteChipsComponent implements OnInit {

  @Input() placeholder: string;

  @Input() items: any[];

  @Input() allItems: any[];

  filteredItems: Observable<string[]>;

  @Input() keyTag: string;

  @Input() keyId: string;
  addOnBlur = true;


  selectedChip: boolean = true;
  removable: boolean = true;
  itemCtrl = new FormControl();
  separatorKeysCodes: number[] = [ENTER, COMMA];

  @ViewChild('itemInput', { static: true }) itemInput: ElementRef<HTMLInputElement>;
  @ViewChild('auto', { static: true }) matAutocomplete: MatAutocomplete;

  constructor() {
  }

  ngOnInit() {

    this.filteredItems = this.itemCtrl.valueChanges.pipe(
      startWith(null),
      map((item: string | null) => item ? this._filter(item) : this.allItems.slice()));
  }


  selected(event: MatAutocompleteSelectedEvent): void {
    let j =0;
      for (let i = 0; i <= this.items.length; i++) {

        if ( j==0 && (this.items.length === 0 || ( this.items[i] && this.items[i][this.keyId] !== event.option.value[this.keyId]))) {
            this.items.push(event.option.value);
            this.itemInput.nativeElement.value = '';
            this.itemCtrl.setValue(null);
            j++;
        }

    }
  }

  remove(item: string): void {
    const index = this.items.indexOf(item);

    if (index >= 0) {
      this.items.splice(index, 1);
    }
  }

  _filter(value: any): any[] {
    if (typeof value !== 'object') {
      return this.allItems.filter(item => item[this.keyTag].toLowerCase().indexOf(value.toLowerCase()) === 0);
    }
  }

}
