import { Component, OnInit, Input } from '@angular/core';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { Usuario } from '../../models/usuario';
import { UsuarioService } from '../../services/usuario/usuario.service';
import Swal from 'sweetalert2';
import { ActivatedRoute, Router } from '@angular/router';

@Component({
  selector: 'app-usuarios',
  templateUrl: './usuarios.component.html',
  styleUrls: ['./usuarios.component.css']
})
export class UsuariosComponent implements OnInit {

  token: string;
  usuario: Usuario;
  @Input() opcion: string;
  @Input() idUser: number;
  cardTitle: string;

  userForm: FormGroup;
  submitted: boolean = false;
  UserOut: Usuario;
  // Mostrar error si ya existe el usuario.
  usedMail: boolean = false;

  constructor(private _usuarioService: UsuarioService,    
    private _activatedRoute: ActivatedRoute,
    private formBuilder: FormBuilder,
    public router: Router) { 
      this.token = _usuarioService.getToken();
      
    }

  ngOnInit() {
    //console.log('opcion ', this.opcion);
    this.userForm = this.formBuilder.group ({      
      nya : ['', Validators.required],
      email : ['', Validators.required],
      rol : ['', Validators.required],
      establi : ['', Validators.required]
    });

    if (this.opcion == 'editar') {
      this.cardTitle = 'Editar Usuario';
      let us = this._usuarioService.getIdentidad();
      console.log('us: ', us.nya);
      let usu:any;
      usu.nya = us.nya;
      usu.email = us.email;
      usu.rol = us.rol;
      usu.establi = us.establi;
          
          console.log('usu: ', usu);
          this.userForm.setValue(usu);
          this.userForm.get('email').disable();  
    } else {
      this.cardTitle = 'Crear Usuario';
    }
    
  }

  saveSeller() {

    if (this.opcion !='editar') {

    // CREO USUARIO

    let user = new Usuario(null, this.userForm.value.email, this.userForm.value.rol, null,
                              this.userForm.value.email, this.userForm.value.nya, this.userForm.value.establi);
    this._usuarioService.singUp(user, false).subscribe(
        Response => {
          console.log(Response);
            if (Response.status === 'success') {
              this.UserOut = Response.usuario;
              Swal.fire('Éxito', 'El usuario fue creado de forma exitosa', 'success');
              this.router.navigate(['/pages/dashboard']);              
            } else {
              Swal.fire('Error', Response.msj , 'error');
              if (Response.msj === 'El usuario ya existe') {
                this.f['email'].setValue(null);
                this.usedMail = true;
              }
            }
          },
          error => {
            console.log(error);
            Swal.fire('Error', 'Algo salio mal!', 'error');
          });
    } else {
        // EDITAR USUARIO      
        this._usuarioService.updateUser(this.idUser, this.userForm.value , this.token).subscribe(
            Response => {
              console.log('Response update user: ', Response);
              if (Response.status === 'success') {
                Swal.fire('Éxito', 'El usuario se edito con éxito', 'success');
              } else {
                Swal.fire('Error', Response.error, 'error');
              }
            },
            error => {
              console.log('error update user: ', error);
            });
    }
  }

  // Facil acceso a los forms fields
  get f() { return this.userForm.controls; }

  onSubmit() {
    this.submitted = true;
    console.log(this.userForm.value);
  }

}
