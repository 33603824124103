import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Order } from '../../models/order';
import { APIURL } from '../apiUrl';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class PresupuestosService {
  
  url: string;
  constructor( private _httpClient: HttpClient) {
    this.url = APIURL.url;
 }

  armarPresupuesto(token: string, order: Order): Observable<any> {

    let json = JSON.stringify(order);
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'generarPresupuesto', params , {headers: headers, responseType: 'json'});
   }

   changePedido(token: string, id: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._httpClient.get(this.url + 'changePedido/' + id , {headers: headers});
  }

   armarPresupuestoCli(token: string, order: Order): Observable<any> {

    let json = JSON.stringify(order);
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'generarPresupuestoCli', params , {headers: headers, responseType: 'json'});
   }

   armarPresupuestosinPDF(token: string, order: Order): Observable<any> {

    let json = JSON.stringify(order);
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'generarPresupuestosinPDF', params , {headers: headers, responseType: 'json'});
   }

   getPresupuesto(token: string, orderid: number): Observable<any>{

    let json = JSON.stringify({ id: orderid});
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'getPresupuesto', params , {headers: headers});
   }

  addOrderLine(token: string, orderId: number, orderLine: any): Observable<any>{
    
    let json = JSON.stringify({ orderId: orderId,
                                orderLine: orderLine});
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'addOrderLine', params , {headers: headers}); 
  }

  deleteOrderLine(token: string, orderId: number, orderLine: any): Observable<any>{
    
    let json = JSON.stringify({ orderId: orderId,
                                orderLine: orderLine});
    let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-Type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);

    return this._httpClient.post(this.url + 'deleteOrderLine', params , {headers: headers}); 
  }

}
