import { Component, OnInit, Renderer2 } from '@angular/core';
import { UsuarioService } from '../../../services/services.index';
import { ClientesService } from '../../../services/clientes/clientes.service';
import { Cliente } from '../../../models/clientes';
import { MatTableDataSource } from '@angular/material/table';
import { VentasService } from '../../../services/ventas/ventas.service';
import { DatePipe } from '@angular/common';
import Swal from 'sweetalert2';
import { SelectionModel } from '@angular/cdk/collections';
import { FormGroup, FormBuilder, FormControl, Validators, FormArray, AbstractControl } from '@angular/forms';
import { Usuario } from '../../../models/usuario';
import { Pago } from '../../../models/pago';
import { ThousandsPipe } from '../../../pipes/thousands.pipe';
import { VendedoresService } from '../../../services/vendedores/vendedores.service';
import { ChequesService } from '../../../services/cheque/cheque.service';
import { Cheque } from '../../../models/cheque';
import { PagosService } from '../../../services/pagos/pagos.service';
import { Vendedor } from '../../../models/vendedor';
import { Recibo } from 'src/app/models/recibo';


@Component({
  selector: 'app-cobrar',
  templateUrl: './cobrar.component.html',
  styleUrls: ['./cobrar.component.css'],
  providers: [DatePipe]
})
export class CobrarComponent implements OnInit {
  // Usuario
  token: string;
  identidad: any;
  dataSourceDetails = new MatTableDataSource<any>([]);
  displayedColumnsDetails: string[] = ['articulo', 'cantidad', 'unitPrice', 'total'];
  dataSourceDevolucion = new MatTableDataSource<any>([]);
  displayedColumnsDevolucion: string[] = ['articulo', 'devueltos', 'unitPrice', 'total'];
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['select', 'idOrder', 'fecha', 'total', 'desc', 'totalSaldo', 'mora', 'payments'];
  dataSourcePagos = new MatTableDataSource<any>([]);
  displayedColumnsPagos: string[] = [
    'id',
    'amount',
    'date',    
    //'user_id',
    'state'
  ];

  banks: string[] = ['BANCO DE GALICIA Y BUENOS AIRES S.A.U.', 'BANCO DE LA NACION ARGENTINA', 'BANCO DE LA PROVINCIA DE BUENOS AIRES', 'INDUSTRIAL AND COMMERCIAL BANK OF CHINA',
'CITIBANK N.A.', 'BBVA BANCO FRANCES S.A.', 'MUFG BANK, LTD', 'BANCO DE LA PROVINCIA DE CORDOBA S.A.', 'BANCO SUPERVIELLE S.A.',
'BANCO DE LA CIUDAD DE BUENOS AIRES', 'BANCO PATAGONIA S.A.', 'BANCO HIPOTECARIO S.A.', 'BANCO DE SAN JUAN S.A.', 'BANCO DEL TUCUMAN S.A.',
'BANCO MUNICIPAL DE ROSARIO', 'BANCO SANTANDER RIO S.A.', 'BANCO DEL CHUBUT S.A.', 'BANCO DE SANTA CRUZ S.A.', 'BANCO DE LA PAMPA SOCIEDAD DE ECONOMÍA M',
'BANCO DE CORRIENTES S.A.', 'BANCO PROVINCIA DEL NEUQUÉN SOCIEDAD ANÓ', 'BRUBANK S.A.U.', 'BANCO INTERFINANZAS S.A.', 'HSBC BANK ARGENTINA S.A.',
'JPMORGAN CHASE BANK, NATIONAL ASSOCIATIO', 'BANCO CREDICOOP COOPERATIVO LIMITADO', 'BANCO DE VALORES S.A.', 'BANCO ROELA S.A.',
'BANCO MARIVA S.A.', 'BANCO ITAU ARGENTINA S.A.', 'BANK OF AMERICA, NATIONAL ASSOCIATION', 'BNP PARIBAS', 'BANCO PROVINCIA DE TIERRA DEL FUEGO',
'BANCO DE LA REPUBLICA ORIENTAL DEL URUGU', 'BANCO SAENZ S.A.', 'BANCO MERIDIAN S.A.', 'BANCO MACRO S.A.', 'BANCO COMAFI SOCIEDAD ANONIMA',
'BANCO DE INVERSION Y COMERCIO EXTERIOR S', 'BANCO PIANO S.A.', 'BANCO JULIO SOCIEDAD ANONIMA', 'BANCO RIOJA SOCIEDAD ANONIMA UNIPERSONAL',
'BANCO DEL SOL S.A.', 'NUEVO BANCO DEL CHACO S. A.', 'BANCO VOII S.A.', 'BANCO DE FORMOSA S.A.', 'BANCO CMF S.A.', 'BANCO DE SANTIAGO DEL ESTERO S.A.',
'BANCO INDUSTRIAL S.A.', 'NUEVO BANCO DE SANTA FE SOCIEDAD ANONIMA', 'BANCO CETELEM ARGENTINA S.A.', 'BANCO DE SERVICIOS FINANCIEROS S.A.',
'BANCO BRADESCO ARGENTINA S.A.U.', 'BANCO DE SERVICIOS Y TRANSACCIONES S.A.', 'RCI BANQUE S.A.', 'BACS BANCO DE CREDITO Y SECURITIZACION S',
'BANCO MASVENTAS S.A.', 'WILOBANK S.A.', 'NUEVO BANCO DE ENTRE RÍOS S.A.', 'BANCO COLUMBIA S.A.', 'BANCO BICA S.A.', 'BANCO COINAG S.A.',
'BANCO DE COMERCIO S.A.', 'FORD CREDIT COMPAÑIA FINANCIERA S.A.', 'COMPAÑIA FINANCIERA ARGENTINA S.A.', 'VOLKSWAGEN FINANCIAL SERVICES COMPAÑIA F',
'CORDIAL COMPAÑÍA FINANCIERA S.A.', 'FCA COMPAÑIA FINANCIERA S.A.', 'GPAT COMPAÑIA FINANCIERA S.A.U.', 'MERCEDES-BENZ COMPAÑÍA FINANCIERA ARGENT',
'ROMBO COMPAÑÍA FINANCIERA S.A.', 'JOHN DEERE CREDIT COMPAÑÍA FINANCIERA S.', 'PSA FINANCE ARGENTINA COMPAÑÍA FINANCIER', 'TOYOTA COMPAÑÍA FINANCIERA DE ARGENTINA',
'FINANDINO COMPAÑIA FINANCIERA S.A.', 'MONTEMAR COMPAÑIA FINANCIERA S.A.', 'TRANSATLANTICA COMPAÑIA FINANCIERA S.A.', 'CAJA DE CREDITO "CUENCA" COOPERATIVA LIM',
  ];
  // ESTADO GENERAL DEL CLIENTE

  anotherArray: string[];
  adeuda: number = 0;
  adeudastr: string;
  entregado: number = 0;
  entregadostr: string;
  saldo: number = 0;
  saldostr: string;
  total: number = 0;
  numRecibo: number = 0;
  observacion: string;
  fecha: string;
  totalcheques: number = 0;
  pago_id: number;
  recibo_id: number;
  desca:number[] = [];
  descb:number[] = [];
  dmanual:number[] = [];
  Order: any;
  devolucion:any;

  // CHECKBOX MAT TABLE
  selection = new SelectionModel<any>(true, []);

  // MAT TABLE VISIBILITY
  matTableFlag: boolean = true;

  // SELECT
  clients: Cliente[] = [];
  selectedClient = null;
  flag: boolean = false;
  panelOpenState :boolean = false;
  contadoDesc :number[] = [];

  ventas:any [] = [];

  // VENDEDOR
  seller: Vendedor;
  labelFecha:any;

  // CHECKBOXES
  checkAdeudadas: boolean = true;
  checkCanceladas: boolean;
  checkTodas: boolean;
  checkPrecanceladas: boolean;

  // BUSCAR FACTURA
  searchingInvoice: number = null;

  // DESCUENTOS
  
  saldosData: number[] = [];
  valueDesc: number[] = [];
  
  // FORMULARIO COBRO
  cobroForm: FormGroup;
  deudaForm: FormGroup;
  submitted: boolean = false;
  deudaInput: boolean = false;

  constructor(private _usuarioService: UsuarioService,
              private _clientesService: ClientesService,
              private _ventasService: VentasService,
              private datepipe: DatePipe,
              private formBuilder: FormBuilder,
              private _vendededoresService: VendedoresService,
              private _pagosService: PagosService,
              private _chequeService: ChequesService) {

    this.identidad = _usuarioService.getIdentidad();
    this.token = _usuarioService.getToken();
   this.anotherArray = this.banks;


    if (this.identidad.rol !== 'admin') {
        this.displayedColumns.splice(this.displayedColumns.length - 1, 1);
    }

    if (this.identidad.rol === 'admin' || this.identidad.rol === 'cobrador') {
      this._clientesService.getClients(this.token).subscribe(
        Response => {
          //console.log('Response: ', Response);
          if (Response.status === 'success') {
            // Asigno datos selected
            this.clients = Response.data;
            this.flag = true;
          }
        },
        error => {
          console.log('error: ', error);
        });
    } else if (this.identidad.rol === 'vendedor') {

      this.clients = this._clientesService.getClientesLocal();
      this.flag = true;
      
    }

  }

  getClients() {

    if (this.identidad.rol === 'admin' || this.identidad.rol === 'cobrador') {
      this._clientesService.getClients(this.token).subscribe(
        Response => {
          // console.log('Response: ', Response);
          if (Response.status === 'success') {
            // Asigno datos selected
            this.clients = Response.data;
            this.flag = true;
          }
        },
        error => {
          console.log('error: ', error);
        });
    } else if (this.identidad.rol === 'vendedor') {
      
      this._clientesService.getSellerClients(this.token, this.seller.user_id).subscribe(
          Response => {
            // console.log('getsellerclients: ', Response.data);
            if (Response.status === 'success') {
              // Asigno datos selected
              this.clients = Response.data;
              this.flag = true;
            }
          },
          error => {
            console.log('error: ', error);
          });
    }
  }

  initDesc() {
    this.dataSource.filteredData.forEach(row => this.selection.select(row));
    this.calculaEstadoGeneral();
    // console.log('selection: ', this.selection);
  }

  confirmInvoice(id: number, i: number) {
    this._ventasService.confirmInvoice(this.token, id).subscribe(
      Response => {
        // console.log('response: ', Response);
        if (Response.status === 'success') {
          Swal.fire('Éxito', 'Cobro confirmado', 'success');
          this.selection.deselect(this.dataSource.data[i]);
          this.deleteRow(id);
        } else {
          Swal.fire('Error', 'No se pudo confirmar el cobro', 'error');
        }
      },
      error => {
        console.log('error: ', error);
      });
  }
  
  loadPagos(id:number) {
    this.dataSourceDevolucion = null;
    this.devolucion = null;
    this.dataSourceDetails = null;
    this.dataSourcePagos = null;
    this._pagosService.getPagos(this.token, id).subscribe(
      Response => {        
        if (Response.status === 'success') {         
          this.dataSourcePagos = new MatTableDataSource(Response.data);
        }
      },
      error => {
        console.log('error: ', error);
      }
    );
    this.loadDetalle(id);
    this.loadDevolucion(id);
  }

  loadDetalle(id:number){
    this._ventasService.getOrder(this.token, id).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.Order = Response.order;          
          this.dataSourceDetails = new MatTableDataSource(this.Order.orderLines);          
        } else {
          Swal.fire('Error', Response.message, 'error');
        }

      },
      error => {
        console.log(error);
        Swal.fire('Error', 'Algo salio mal!', 'error');
      });
  }

  loadDevolucion(id:number){
    this._ventasService.getDevolucion(this.token, id).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.devolucion = Response.order.orderLine;
          this.dataSourceDevolucion = new MatTableDataSource(Response.order.orderLine); 
          console.log('devo', Response.order.orderLine);         
        } else {
          Swal.fire('Error', Response.message, 'error');
        }

      },
      error => {
        console.log(error);
        Swal.fire('Error', 'Algo salio mal!', 'error');
      });
  }

  getInvoices() {
    if (this.selectedClient) {
      this.selection.clear();
    // console.log('selectedClient: ', this.selectedClient);
    let option = null;
    if (this.checkAdeudadas) {
        option = 1;
    } else if (this.checkCanceladas) {      
        option = 2;      
    } else if (this.checkPrecanceladas) {
        option = 3;
    }

    this._ventasService.getClientInvoices(this.token, this.selectedClient.codCliente, option).subscribe(
      Response => {
        // console.log('Response Invoices Clients: ', Response);
        if (Response.status === 'success') {
          if (Response.data.length > 0) {
              this.matTableFlag = false;
          }
          this.ventas = Response.data;
          this.dataSource = new MatTableDataSource(Response.data);
          this.initDesc();
        }
      },
      error => {
        console.log('error Invoices client', error);
      });
    } else {
      Swal.fire('Cuidado!', 'No se ha seleccionado ningun cliente', 'warning');
    }
  }

  calculaEstadoGeneral() {
    this.adeuda = 0;
    this.saldo = 0;
    this.entregado = 0;
    let auxAdeuda: string;
    let auxSaldo: string;
    let auxEntregado: string;

    for (let i = 0; i < this.selection.selected.length; i++) {
      auxAdeuda = (this.selection.selected[i].total).toFixed(2);
      this.adeuda += Number(auxAdeuda);
      // this.adeuda += this.selection.selected[i].total;
      auxSaldo = this.selection.selected[i].saldo.toFixed(2);
      this.saldo += Number(auxSaldo);
      // this.saldo += this.selection.selected[i].saldo;
       auxEntregado = Number(this.selection.selected[i].total - this.selection.selected[i].saldo).toFixed(2);
      // this.entregado += this.selection.selected[i].total - this.selection.selected[i].saldo;
      this.entregado += Number(auxEntregado);
    }
    this.adeudastr = new ThousandsPipe().transform(this.adeuda.toFixed(2));
    this.saldostr = new ThousandsPipe().transform(this.saldo.toFixed(2));
    this.entregadostr = new ThousandsPipe().transform(this.entregado.toFixed(2));
    
    for (let i = 0; i < this.dataSource.data.length; i++) {
      
      this.saldosData[i] = this.dataSource.data[i].saldo;
      this.valueDesc[i] = 0;
      this.contadoDesc[i] = 0;
      this.desca[i] = 0;
      //this.descb[i] = 0;
      this.dmanual[i] = 0;
    }
  }

  isAllSelected() {
    const numSelected = this.selection.selected.length;
    const numRows = this.dataSource.filteredData.length;
    return numSelected === numRows;
  }

  masterToggle() {
   if (this.isAllSelected()) {
    this.selection.clear();
    this.calculaEstadoGeneral();
   } else {
     this.dataSource.filteredData.forEach(row => this.selection.select(row));
     this.calculaEstadoGeneral();
   }
  }

  selected(event) {
    this.adeuda = 0;
    this.saldo = 0;
    this.entregado = 0;

    for (let i = 0; i < this.selection.selected.length; i++) {
      this.adeuda += parseFloat(this.selection.selected[i].total.toFixed(2));
      this.saldo += parseFloat(this.selection.selected[i].saldo.toFixed(2));
      this.entregado += parseFloat(this.selection.selected[i].total.toFixed(2)) - parseFloat(this.selection.selected[i].saldo.toFixed(2));
    }
  }

  cobro() {
    Swal.fire(
      {
        title: 'Estas Seguro?',
        type: 'warning',
        text: 'El pago sera ingresado en el sistema',
        showConfirmButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
      }
    ).then ((result) => {
       if (result.value) {
        let resto: number = 0;
        let auxTotal: number = Number(this.cobroForm.value.total);
        let orders: string = '';
        //let errodesc:number =0;
        let res:number =auxTotal;
        let orden:number = 0;
        if(this.ventas[0].idOrder != this.selection.selected[0].idOrder){
          for (let i = 1; i < this.ventas.length; i++) {
            //console.log('for',this.ventas[i].idOrder , this.selection.selected[0].idOrder);
              if(this.ventas[i].idOrder == this.selection.selected[0].idOrder)
                orden = i;
               
          }
        }
        for (let i = 0; i < this.selection.selected.length; i++) {
          console.log(Math.round(this.selection.selected[i].saldo),Math.round(res) );
          console.log(this.desca[i-orden],this.dmanual[i-orden]);
          //console.log(this.selection.selected);
          if(Math.round(this.selection.selected[i].saldo)-1 > Math.round(res) && ((typeof this.desca[i-orden] !=='undefined' && this.desca[i-orden] != 0) || (typeof this.dmanual[i-orden] !=='undefined' && this.dmanual[i-orden] != 0)) ){
            //console.log()
            Swal.fire('Error', 'Estas aplicando descuento en una factura que no se cancela! <br> Retira el descuento en la factura '+ this.selection.selected[i].idOrder, 'error');
            return;
          }
          res -= this.selection.selected[i].saldo;
        };
        
        this.selection.selected.sort(function(a, b) {
          // console.log('a', a);
          return new Date(a.fecha).getTime() - new Date(b.fecha).getTime();
        });
    
        let validaTotal = 0;
    
        this.selection.selected.forEach(ele => {
            validaTotal += ele.saldo;
        });
        //console.log('monton', auxTotal, validaTotal);
        if (Math.round(auxTotal) <= Math.round(validaTotal)) {
          let state = 0;
          let aprobed_id=null;
          if (this.identidad.rol === 'admin') {
            state = 1;
            aprobed_id= this.identidad.sub;
          }
          let recibo = new Recibo(null, 
            this.selectedClient.codCliente,
            this.cobroForm.value.total,
            this.identidad.sub,
            aprobed_id,
            this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss'),
            state,
            null,
            this.cobroForm.value.observacion);          
          this._pagosService.saveRecibo(this.token, recibo).subscribe(
            Response => {
              if (Response.status === 'success') {
              this.recibo_id = Response.recibo_id;

              for (let i = 0; i < this.selection.selected.length; i++) {
                if (auxTotal > 0) {
          
                    orders += this.selection.selected[i].idOrder.toString() + ',';
          
                    if (this.selection.selected[i].saldo >= 0) {
          
                      this.selection.selected[i].saldo -= auxTotal;
                      this.selection.selected[i].saldo = parseFloat(this.selection.selected[i].saldo.toFixed(2));
          
                    if (this.selection.selected[i].saldo < 0) {
                        resto = this.selection.selected[i].saldo;
                        // hacer pago de factura
                        let amountPago = resto + auxTotal;  
                        this.guardarPago(this.selection.selected[i], amountPago);
                        auxTotal = resto * -1;
                        this.selection.selected[i].saldo = 0;
                        this.selection.selected[i].clear = 1;
                        this.deleteRow(this.selection.selected[i].idOrder);
                        if (this.identidad.rol === 'admin') {
                          this.selection.selected[i].pagada = 1;
                        } else {
                          this.selection.selected[i].pagada = 2;
                        }
                    } else if (this.selection.selected[i].saldo >= 0) {
                                // hacer pago de factura
                               
                                this.guardarPago(this.selection.selected[i], auxTotal);
                        if (this.selection.selected[i].saldo === 0) {
                          this.deleteRow(this.selection.selected[i].idOrder);
                          if (this.identidad.rol === 'admin') {
                            this.selection.selected[i].pagada = 1;
                          } else {
                            this.selection.selected[i].pagada = 2;
                          }
                          this.selection.selected[i].clear = 1;
                        }
                        auxTotal = 0;
                    }
                  }
                }
              }
          
              this._ventasService.cobranzaInvoice(this.token, this.selection.selected).subscribe(
                Response => {
                  // console.log('Response: ', Response);
                  if (Response.status === 'success') {
                    Swal.fire('Éxito', 'El cobro se realizo con éxito', 'success');
                    this.ngOnInit();
                    this.clearSelectedPaid();
                    this.calculaEstadoGeneral();
                    // console.log('selection: ', this.selection);
                  } else {
                    Swal.fire('Error', Response.message, 'error');
                  }
                },
                error => {
                  console.log('error', error);
                  Swal.fire('Error', 'Algo salio mal!', 'error');
                });
          
              console.log('selection: ', this.selection.selected);
              }
              else{
                  console.log('error',Response.message);
              }
            },
            error =>{
              console.log('error',error);
            });

       
    
        } else {
          Swal.fire('Cuidado', 'El monto ingresado es superior al saldo total a cobrar', 'warning');
        }
        // this.cheq_carg = false;
       }
    });
    
  }

  guardarPago(order: any, amount: number) {
      
      
      let pago = new Pago(null,
                          this.recibo_id,
                          order.idOrder,
                          amount,
                          order.dCobranza);
                          console.log('fecha',pago);
      this._pagosService.guardarPago(this.token, pago).subscribe(
        Response => {
          this.pago_id = Response.id_pago;
          
          if (this.cobroForm.get('cheques') && this.pago_id){
            this.cobroForm.get('cheques').value.forEach(che => {
              let cheque = new Cheque(null,
                                    che.number,
                                    this.selectedClient.codCliente,
                                    this.pago_id,
                                    che.bank,
                                    che.amount,
                                    this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss'),
                                    this.datepipe.transform(che.end_date, 'yyyy-MM-dd hh:mm:ss'),
                                    'pendiente');
                                    //console.log('cheque: ', cheque);
             this._chequeService.guardarCheque(this.token, cheque).subscribe(
              Response => {
                console.log('cheque: ', Response);
              },
              error => {
                console.log('error :', error);
              });
            });
            let id: number = 0;
            this.cobroForm.get('cheques').value.forEach(element => {
              id++;
            });
            for(let i=id-1 ; i >= 0 ; i--)
            {
              (<FormArray>this.cobroForm.get('cheques')).removeAt(i);
            }
            console.log("formo: ", this.cobroForm.get('cheques'));
            this.calculaTotales();
          } else{
            console.log("no esta llegando el id_pago", this.pago_id);
          }
        },
        error => {
          console.log('error :', error);
        });

        // if (this.cobroForm.get('cheques')) {
        //   this.cobroForm.get('cheques').value.forEach(che => {
        //     let cheque = new Cheque(null,
        //                           che.number,
        //                           this.selectedClient.codCliente,
        //                           this.pago_id,
        //                           che.bank,
        //                           che.amount,
        //                           this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss'),
        //                           this.datepipe.transform(che.end_date, 'yyyy-MM-dd hh:mm:ss'),
        //                           'pendiente');
        //                           console.log('cheque: ', cheque);
        //    this._chequeService.guardarCheque(this.token, cheque).subscribe(
        //     Response => {
        //       console.log('cheque: ', Response);
        //     },
        //     error => {
        //       console.log('error :', error);
        //     });
        //   });
        //   let id: number = 0;
        //   this.cobroForm.get('cheques').value.forEach(element => {
        //     (<FormArray>this.cobroForm.get('cheques')).removeAt(id);
        //     this.calculaTotales();
        //     id++;
        //   });
        // }
  }

  clearSelectedPaid() {
    for (let i = 0; i < this.selection.selected.length; i++) {
      if (this.selection.selected[i].clear) {
        this.selection.deselect(this.selection.selected[i]);
      }
    }
    this.cobroForm.controls.total.reset();
  }

  addChequeFormGroup(): FormGroup {
      return this.formBuilder.group ({
        number : ['', Validators.required],
        end_date : ['', Validators.required],
        amount : ['', Validators.required],
        bank : ['', Validators.required]
      });
  }

  addChequeButtonFormGroup(): void {
    (<FormArray>this.cobroForm.get('cheques')).push(this.addChequeFormGroup());
    // console.log('form: ', this.cobroForm.value);
  }

  deleteCheque(id: number): void {
    (<FormArray>this.cobroForm.get('cheques')).removeAt(id);
    this.calculaTotales();
  }

  calculaTotales() {
    this.totalcheques = 0;
    this.cobroForm.get('cheques').value.forEach(element => {
      this.totalcheques += element.amount;
    });
  }

  deleteRow(id: number) {
      for (let i = 0; i < this.dataSource.data.length; i++) {
        if (id === this.dataSource.data[i].idOrder) {
            this.dataSource.data.splice(i, 1);
        }
      }
      this.dataSource._updateChangeSubscription();
      // console.log('datasourcedata: ', this.dataSource.data);
  }

  getDiffDays(fechaCompra: any): any {
    fechaCompra = new Date(fechaCompra).getTime();
    let today = new Date().getTime();
    let diff = today - fechaCompra;

    return (diff / (1000 * 60 * 60 * 24)).toFixed(0);
    // return today.diff()
  }

  getInvoice() {

    if (this.searchingInvoice) {
      this.selection.clear();
        this._ventasService.getInvoice(this.token, this.searchingInvoice).subscribe(
          Response => {
            // console.log('Response get invoice: ', Response);
            if (Response.status === 'success') {
              this.clearDataTable();
              let aux = [];
              aux.push(Response.data);
              if (aux.length > 0) {
                this.matTableFlag = false;
              }
              this.dataSource = new MatTableDataSource(aux);
              this.initDesc();
            } else {
              Swal.fire('Error', Response.message, 'error');
            }
          },
          error => {
            console.log('error get invoice: ', error);
          });
    }
  }

  clearDataTable() {
      this.selection.clear();
      this.dataSource.data = [];
      this.calculaEstadoGeneral();
  }

  statusCheckAdeudadas() {
    if (this.checkAdeudadas) {
        this.checkCanceladas = false;
        this.checkTodas = false;
        this.checkPrecanceladas = false;
    }
    this.clearDataTable();
    this.getInvoices();
  }

  statusCheckCanceladas() {
    if (this.checkCanceladas) {
      this.checkAdeudadas = false;
      this.checkTodas = false;
      this.checkPrecanceladas = false;
    }
    this.clearDataTable();
    this.getInvoices();
  }

  statusCheckTodas() {
    if (this.checkTodas) {
      this.checkAdeudadas = false;
      this.checkCanceladas = false;
      this.checkPrecanceladas = false;
    }
    this.clearDataTable();
    this.getInvoices();
  }

  statusCheckPrecanceladas() {
    if (this.checkPrecanceladas) {
      this.checkAdeudadas = false;
      this.checkCanceladas = false;
      this.checkTodas = false;
    }
    this.clearDataTable();
    this.getInvoices();
  }
  

  addPercent(i: number) {
    // if(this.desca[i] == 1 && this.descb[i] == 1){
    //   this.valueDesc[i] = ((this.dataSource.data[i].total * 4.75) / 100);
    //   this.dataSource.data[i].dCobranza += this.valueDesc[i];
     
    //   this.dataSource.data[i].saldo = this.dataSource.data[i].saldo - this.valueDesc[i];
    //   console.log('cobranza: ',this.dataSource.data[i].dCobranza);
    // }else{
      this.valueDesc[i] = ((this.dataSource.data[i].total * 5) / 100);
      this.dataSource.data[i].dCobranza = this.valueDesc[i];
      
      this.dataSource.data[i].saldo = this.dataSource.data[i].saldo - this.valueDesc[i];
      console.log('cobranza: ',this.dataSource.data[i].dCobranza);
    // }
  }

  rmPercent(i:number){
      // if(this.desca[i] == 0 && this.descb[i] == 0){
        this.valueDesc[i] = 0;
        this.dataSource.data[i].dCobranza = 0;       
        this.dataSource.data[i].saldo = this.saldosData[i];
        console.log('rmPercent: ',this.dataSource.data[i].dCobranza);
      // }else{
        // this.dataSource.data[i].dCobranza = 0;
        // this.dataSource.data[i].saldo = this.saldosData[i];
        // this.valueDesc[i] = ((this.dataSource.data[i].saldo * 5) / 100);
        // this.dataSource.data[i].dCobranza = this.valueDesc[i];        
        // this.dataSource.data[i].saldo = this.dataSource.data[i].saldo - this.valueDesc[i];
        // console.log('rmPercent: ',this.dataSource.data[i].dCobranza);
      // }
  }

  OnChange(event: any, option: string, i: number) {
    if (event === true) {
      if (option === '30dias') {
        this.desca[i] = 1;
        this.addPercent(i);
        console.log('desc',i);
      }
      // if (option === 'mayorista') {
      //   this.descb[i] = 1;
      //   this.addPercent(i);
      // }
    }else {      
      if (option === '30dias') {
        this.desca[i] = 0;
        this.rmPercent(i);
      }
      // if (option === 'mayorista') {
      //   this.descb[i] = 0;
      //   this.rmPercent(i);
      // }
    }
  }

  OnChangeDesc(event: any, i: number) {
    if (event === true) {      
      this.valueDesc[i] = ((this.dataSource.data[i].total * this.dmanual[i]) / 100);
      this.dataSource.data[i].dCobranza = this.valueDesc[i];
      
      this.dataSource.data[i].saldo = this.dataSource.data[i].saldo - this.valueDesc[i];
      console.log('cobranza: ',this.dataSource.data[i].dCobranza);
      console.log('manual',this.dmanual[i]);

    }else {      
      this.valueDesc[i] = 0;
      this.dataSource.data[i].dCobranza = 0;
      this.dataSource.data[i].saldo = this.saldosData[i];
      console.log('rmPercent: ',this.dataSource.data[i].dCobranza);
    }
  }

  precioContado(event: any, i: number){
    if (event === true) {
      let des:number = this.dataSource.data[i].saldo;
      this.contadoDesc[i] = 1;
      this.dataSource.data[i].saldo = this.dataSource.data[i].saldo / 1.3;
      this.dataSource.data[i].total = this.dataSource.data[i].total / 1.3;
      this.dataSource.data[i].dCobranza = this.dataSource.data[i].total - des;
    }else{
      this.contadoDesc[i] = 0;
      this.dataSource.data[i].saldo = this.dataSource.data[i].saldo * 1.3;
      this.dataSource.data[i].total = this.dataSource.data[i].total * 1.3;
      this.dataSource.data[i].dCobranza = 0;
    }
  }

  ngOnInit() {    
    this.labelFecha = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    this.cobroForm = this.formBuilder.group ({
      // fecha : [today, Validators.required],
      total : ['', Validators.required],
      observacion : [''],
      cheques: this.formBuilder.array([ ])
    });
  }

  // Facil acceso a los forms fields
  get f() { return this.cobroForm.controls; }
  get d() { return this.deudaForm.controls; }
  get ch() { return (<FormArray>this.cobroForm.get('cheques')).controls; }

  onSubmit() {
    this.submitted = true;
  }

      filterListCareUnit(val) {
        console.log(val);
        this.banks = this.anotherArray.filter((unit) => unit.indexOf(val) > -1);
      }

}
