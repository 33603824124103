import { Injectable } from '@angular/core';
// Otros
import { Observable } from 'rxjs';
import { APIURL } from '../apiUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
// Modelo
import { Pago } from 'src/app/models/pago';

@Injectable({
  providedIn: 'root'
})
export class PagosService {

  url: string;

  constructor( public _http: HttpClient) {
    this.url = APIURL.url;
  }

  guardarPago(token: string, pago: Pago): Observable<any> {

    let json = JSON.stringify(pago);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

    return this._http.post(this.url + 'pagos', params , {headers: headers});
  }

  getPagos(token: string, id: number): Observable <any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

    return this._http.get(this.url + 'pagos/getPagosOrder/' + id , {headers: headers});
  }

  getAllPagos(token: string): Observable <any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

    return this._http.get(this.url + 'getAllPagos' , {headers: headers});
  }


  generaReporte(token: string, idClient: number): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.get(this.url + 'generaReporte/' + idClient , {headers: headers});
  }

  generaReporteXVend(token: string, idVend: number, from: string, to: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.get(this.url + 'generaReporteXVend/' + idVend + '/' + from + '/' + to , {headers: headers});
  }

  generaReporteXResumenCli(token: string, from: string, to: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
    .set('Authorization', token);

    return this._http.get(this.url + 'generaReporteXResumenCli/' + from + '/' + to , {headers: headers});
  }

  saveRecibo(token: string, recibo: any): Observable<any> {

    let json = JSON.stringify(recibo);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

    return this._http.post(this.url + 'saveRecibo', params , {headers: headers});
  }

  updatePago(token: string, id: number, pago: Pago): Observable<any> {

    let json = JSON.stringify(pago);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

    return this._http.put(this.url + 'pagos/' + id, params , {headers: headers});
  }
}
