import { Injectable } from '@angular/core';
// Otros
import { Observable } from 'rxjs';
import { APIURL } from '../apiUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Cheque } from '../../models/cheque';
// Modelo


@Injectable({
  providedIn: 'root'
})
export class ChequesService {

  url: string;

  constructor( public _http: HttpClient) {
    this.url = APIURL.url;
  }

  guardarCheque(token: string, cheque: Cheque): Observable<any> {

    let json = JSON.stringify(cheque);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

    return this._http.post(this.url + 'cheques', params , {headers: headers});
  }

  getCheques(token: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

    return this._http.get(this.url + 'getCheques', {headers: headers});
  }

  updateCheque(token: string, id:number, cheque: Cheque): Observable<any> {

    let json = JSON.stringify(cheque);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                  .set('Authorization', token);

    return this._http.put(this.url + 'cheques' + id, params , {headers: headers});
  }
}