export class Caja {

    public user_id: number;
    public observacion: string;
    public total:number;
    public id_cliente: number;
    public cheque1: string;
    public cheque2: string;
    public cheque3: string;
    public cheque4: string;
    public cheque5: string;
    public mcheque1: number;
    public mcheque2: number;
    public mcheque3: number;
    public mcheque4: number;
    public mcheque5: number;

    constructor(user_id: number, observacion: string,total:number,id_cliente: number,cheque1: string,cheque2: string,cheque3: string,
        cheque4: string,cheque5: string,mcheque1: number,mcheque2: number,mcheque3: number,mcheque4: number,mcheque5: number) {
        this.user_id = user_id;
        this.observacion= observacion;
        this.total=total;
        this.id_cliente=id_cliente;
        this.cheque1=cheque1;
        this.cheque2=cheque2;
        this.cheque3=cheque3;
        this.cheque4=cheque4;
        this.cheque5=cheque5;
        this.mcheque1=mcheque1;
        this.mcheque2=mcheque2;
        this.mcheque3=mcheque3;
        this.mcheque4=mcheque4;
        this.mcheque5=mcheque5;
    }
}