import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class StatesService {

   provincias: any = [
    {
        state: 'Capital Federal'
    },
    {
        state: 'Buenos Aires'
    },
    {
        state: 'Catamarca'
    },
    {
        state: 'Chaco'
    },
    {
        state: 'Chubut'
    },
    {
        state: 'Cordoba'
    },
    {
        state: 'Corrientes'
    },
    {
        state: 'Entre Rios'
    },
    {
        state: 'Formosa'
    },
    {
        state: 'Jujuy'
    },
    {
        state: 'La Pampa'
    },
    {
        state: 'La Rioja'
    },
    {
        state: 'Mendoza'
    },
    {
        state: 'Misiones'
    },
    {
        state: 'Neuquen'
    },
    {
        state: 'Rio Negro'
    },
    {
        state: 'Salta'
    },
    {
        state: 'San Juan'
    },
    {
        state: 'San Luis',
    },
    {
        state: 'Santa Cruz'
    },
    {
        state: 'Santa Fe'
    },
    {
        state: 'Santiago del Estero'
    },
    {
        state: 'Tierra del Fuego'
    },
    {
        state: 'Tucuman'
    }
];


  constructor() { }
}
