import { Component, OnInit, Optional, Inject } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioService } from '../../../../services/usuario/usuario.service';
import { MatDialog, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { VentasService } from '../../../../services/ventas/ventas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-detalle',
  templateUrl: './detalle.component.html',
  styleUrls: ['./detalle.component.css']
})
export class DetalleComponent implements OnInit {

   // Usuario
   token: string;
   identidad: any;
   flag = false;
   //porvencer: boolean = false;

   dataSourceArt = new MatTableDataSource<any>([]);
   displayedColumnsArt: string[] = ['articulo', 'total', 'comision', 'comisionado'];

   Order: any;
   pago:boolean = false;

  constructor(private _usuarioService: UsuarioService, private _ventasService: VentasService,
    public dialog: MatDialog,
    @Optional() @Inject(MAT_DIALOG_DATA) data: any) {
    this.identidad = _usuarioService.getIdentidad();
    this.token = _usuarioService.getToken();
    if(data.pago == "CC")
      this.pago = true;
    else
      this.pago = false;
    this._ventasService.getOrder(this.token, data.id).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.Order = Response.order;
          this.dataSourceArt = new MatTableDataSource(this.Order.orderLines);
        } else {
          Swal.fire('Error', Response.message, 'error');
        }

      },
      error => {
        console.log(error);
        Swal.fire('Error', 'Algo salio mal!', 'error');
      });
   }

  ngOnInit() {
  }

}
