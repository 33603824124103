import { Component, OnInit, Inject } from '@angular/core';
import { FormGroup, FormBuilder} from '@angular/forms';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material/dialog';

@Component({
  selector: 'app-add-comment',
  templateUrl: './add-comment.component.html',
  styles: []
})
export class AddCommentComponent implements OnInit {

  title: string;
  input_title: string;
  mat_icon: string;
  placeholder: string;
  btn_title: string;
  commentForm: FormGroup;

  constructor(private formBuilder: FormBuilder,
              @Inject(MAT_DIALOG_DATA) public data: any,
              public dialogRef: MatDialogRef<AddCommentComponent>) { }

  ngOnInit() {
    this.commentForm = this.formBuilder.group ({
      comment : [''],
    });

    this.title = this.data.title;
    this.input_title = this.data.input_title;
    this.mat_icon = this.data.mat_icon;
    this.placeholder = this.data.placeholder;
    this.btn_title = this.data.btn_title;

  }

  saveComment() {
    this.dialogRef.close(this.commentForm.value.comment);
  }

  closeDialog() {
    this.dialogRef.close('cancel');
  }


  // Facil acceso a los forms fields desde el template
  // get f() { return this.commentForm.controls; }

}
