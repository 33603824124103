import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/services/services.index';
import Swal from 'sweetalert2';
import { Usuario } from '../../../models/usuario';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editar-perfil',
  templateUrl: './editar-perfil.component.html',
  styleUrls: ['./editar-perfil.component.css']
})

export class EditarPerfilComponent implements OnInit {

  usuario: any;
  token: string;
  identidad: Usuario;

  constructor(private _userService: UsuarioService, public router: Router, private _activatedRoute: ActivatedRoute) {
    this.usuario = _userService.getIdentidad();
    console.log('usuario: ', this.usuario);
    this.token = _userService.getToken();


  }

  ngOnInit() {
  }


}
