// Modulos
import { NgModule } from '@angular/core';
import {FormsModule, ReactiveFormsModule} from '@angular/forms'
import { MatFormFieldModule } from '@angular/material/form-field';
// Otros
import { ActivatedRoute, Router } from '@angular/router';

// Componentes
import { LoginComponent } from './login.component';
import { RegisterComponent } from './register.component';
import { ChangepwComponent } from './changepw.component';
import { DialogChangepwComponent } from './dialog-changepw/dialog-changepw';
import { AngularMaterialModule } from '../angular-material/angular-material.module';




@NgModule({
    declarations: [
        LoginComponent,
        RegisterComponent,
        ChangepwComponent,
        DialogChangepwComponent,

    ],
    exports: [
        LoginComponent,
        RegisterComponent,
        MatFormFieldModule,
        ChangepwComponent
    ],
    imports: [
        FormsModule,
        ReactiveFormsModule,
        AngularMaterialModule,
        MatFormFieldModule,

        
    ], 
    entryComponents: [
        DialogChangepwComponent,
    ]
})

export class LoginModule { }
