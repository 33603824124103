
// Otros
import { Component, OnInit } from '@angular/core';
import { Router, ActivatedRoute } from '@angular/router';
import Swal from 'sweetalert2';

// Modelos
import { Usuario } from '../models/usuario';

// Servicios
import { UsuarioService } from '../services/usuario/usuario.service';


declare function init_plugins();

@Component({
  selector: 'app-changepw',
  templateUrl: './changepw.component.html',
  styleUrls: ['./changepw.component.css'],
  providers: [UsuarioService]
})
export class ChangepwComponent implements OnInit {

  public form = {

    email: null,

    password: null,

    password_confirmation: null,

    resetToken : null
  }

  constructor(
    private route: ActivatedRoute ,
    public router: Router,
    private _userService: UsuarioService,
    ) {

      route.queryParams.subscribe(params => {
        this.form.resetToken = params['token'],
        this.form.email = params['email']
      });
  }

  ngOnInit() {
    init_plugins();
  }

  resetPass(){
    if(this.form.password==this.form.password_confirmation && this.form.password && this.form.password_confirmation){
      
      this._userService.changeRecoverPass(this.form.email, this.form.password, this.form.resetToken).subscribe(
       Response => {
        console.log(Response);
        if (Response.status === 'success') {
          Swal.fire('Exito', 'Su contraseña fue cambiada con exito, reingrese al sistema para continuar', 'success');
          this.router.navigate(['/login']);
        } else {
          Swal.fire('Cuidado!', 'Hubo un error al modificar su contraseña', 'warning');
          console.log(Response.message);
          }
        },
       error => {
          console.log('error: ', error);
      });
    }
    else {
      Swal.fire('Cuidado!', 'Verifique los datos ingresados', 'warning');
    }


  }


}

