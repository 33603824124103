import { Injectable } from '@angular/core';

// Otros
import { Observable } from 'rxjs';
import { APIURL } from '../apiUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';

// Modelo
import { Category } from '../../models/category';

@Injectable({
  providedIn: 'root'
})

export class CategoriesService {

  url: string;
  identity;
  token;

  constructor( public _http: HttpClient) {
    this.url = APIURL.url;
  }

  // Obtengo los códigos de los categories para el select dinámico
obtenerCateg( token): Observable<any> { 
       let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                      .set('Authorization', token);
  
      return this._http.get(this.url + 'getCategories', {headers: headers});
  }

}