import { Injectable } from '@angular/core';
import { APIURL } from '../apiUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Vendedor } from '../../models/vendedor';
import { Observable } from 'rxjs';
import { Cliente } from '../../models/clientes';

@Injectable({
  providedIn: 'root'
})
export class VendedoresService {

  url: string;

  constructor(public _http: HttpClient) {
    this.url = APIURL.url;
  }

  saveSeller(seller: Vendedor, token, choosenClients: Cliente[], sub: number): Observable<any> {
    seller.clients = choosenClients;
    seller.user_id = sub;

    let json = JSON.stringify(seller);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Authorization', token)
                                   .set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.post(this.url + 'sellers', params, {headers: headers});
  }

  updateSeller(id: number, seller: Vendedor, token: string, choosenClients: Cliente[]): Observable<any> {
      seller.clients = choosenClients;
      let json = JSON.stringify(seller);
      let params = 'json=' + json;

      let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                     .set('Authorization', token);

    return this._http.patch(this.url + 'sellers/' + id, {params: json}, {headers: headers});
  }

  deleteSeller(id: number, token: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                   .set('Authorization', token);

    return this._http.delete(this.url + 'sellers/' + id, {headers: headers});
  }

  getSellers(token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                   .set('Authorization', token);

    return this._http.get(this.url + 'sellers', {headers: headers});
  }

  getSeller(id: number, token: string): Observable<any> {

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                   .set('Authorization', token);

    return this._http.get(this.url + 'sellers/' + id, {headers: headers});
  }

  getSellerUser(id: number, token: string): Observable<any> {
    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded')
                                   .set('Authorization', token);

    return this._http.get(this.url + 'sellerUser/' + id, {headers: headers});
  }
  
  
}
