  import { Component, OnInit, Renderer2, ElementRef, Input, ViewChild } from '@angular/core';
  // Servicios
  import { ClientesService } from '../../../services/clientes/clientes.service';
  import { UsuarioService } from '../../../services/usuario/usuario.service';
  import { ArticulosService } from '../../../services/articulos/articulos.service';
  import { VentasService } from '../../../services/ventas/ventas.service';
  import { VendedoresService } from '../../../services/vendedores/vendedores.service';
  // Modelos
  import { Order } from '../../../models/order';
  import { Cliente } from '../../../models/clientes';
  import { Usuario } from '../../../models/usuario';
  import { DatePipe } from '@angular/common';
  // Angular material
  import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
  import { MatTableDataSource } from '@angular/material/table';
  
  // LOADASH
  import * as _ from 'lodash';
  // SweetAlert
  import Swal from 'sweetalert2';
  import { EstablishmentsService } from '../../../services/establishments/establishments.service';
  import { Establishment } from '../../../models/establishment';
  
  import { FormGroup, FormBuilder, Validators } from '@angular/forms';
  
  import { APIURL } from '../../../services/apiUrl';
  import { Router } from '@angular/router';
  import { PresupuestosService } from '../../../services/presupuestos/presupuestos.service';
import { ArtiOca } from 'src/app/models/artiOca';
  
  @Component({
    selector: 'app-vender',
    templateUrl: './vender.component.html',
    styleUrls: ['./vender.component.css'],
    providers: [DatePipe, MatDialog]
  })
  export class VenderComponent implements OnInit {
  
    @ViewChild('cantidad', { static: false }) inputCantidad: ElementRef;
    // @ViewChild('rentabilidad', { static: false }) inputRentabilidad: ElementRef;
  
    total: number = 0;
    // Total
    totalAux: number[] = [];
    articlesAuxuni: number[] = [];
    articlesAuxtot: number[] = [];
    submitted: boolean = false;
  
    order: any;
    aux: any = null;
    hideInv: boolean = false;
    ocasional: boolean = false;
    finarti: boolean = false;
    contDescuentos:number = 0;
    NombreOcasional:string = 'Presupuesto';
  
    observationsFormGroup: FormGroup;
    articulosFormGroup: FormGroup;
  
     // Descuentos
     discounts: any = {
      discountR: 0,
      discountC: 0,
      rechargeCC: 0,
      discountCant: 0,
      discountCards: 0
    };
  
    depo:boolean = false;
    durle: boolean =false;
    // mat table
    dataSource = new MatTableDataSource<any>([]);
    dataSourceArticulos = new MatTableDataSource<any>([]); 
    dataSourceSearchArticles = new MatTableDataSource<any>([]);
    displayedColumnsSearchArticles: string [];
    displayedColumnsArticulos: string[] = ['imagen','descripcion', 'cantidad', 'precioXuni', 'total','rent', 'acciones'];
  
    url:string;
    clients: Cliente[];
    clients1: Cliente[];
    clients2: Cliente[];
    selectedClient: any = null;
    cantArticle: number;
    rentabilidad: number = 20;
    flag: boolean = false;
    flagclientes: boolean = false;
    flagarticles: boolean = false;
    establishments: Establishment[] = [];
  
    articles: any[];
    articlesSelected: any[] = [];
    // artiAux: any = {};
    selectedArticulo: any = null;
  
    token: string;
    identidad: any;
    establishment_id: number;
    clientedc:boolean = false;
    clientetom:boolean = false;
    vendedor:boolean = false;
    seller: any;
  
    constructor( private _clientsService: ClientesService, private _userService: UsuarioService,
                 private _articlesService: ArticulosService, private renderer: Renderer2, private router: Router,
                 private _ventasService: VentasService, private datepipe: DatePipe, public dialog: MatDialog,
                 private _establishmentsService: EstablishmentsService, private _presupService: PresupuestosService,
                 private _vendedoresService: VendedoresService, private _formBuilder: FormBuilder ) {
      this.order = new Order(null, null, null, null, [], null, null, null, null);
      this.order.fecha = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
      this.order.dateTime = this.datepipe.transform(new Date(), 'yyyy-MM-ddThh:mm:ss');
      this.order.user_id = this._userService.getIdentidad().sub;
      this.token = _userService.getToken();
      this.establishment_id = _userService.getIdentidad().establi;
      this.url = APIURL.urlPublic;
      this.identidad = _userService.getIdentidad();
      if(this.identidad.rol == 'clientedc'){
        this.clientedc = true;
        this.flagclientes = true;
        this.displayedColumnsSearchArticles = ['codArticulo', 'descripcion'];
  
        this._clientsService.getClientUserID(this.token, this.identidad.sub).subscribe(
          Response => {
            if (Response.status === 'success') {              
                this.selectedClient = Response.data;
            } else {
              console.log(Response);
          }
          },
          error => {
            console.log(error);
          });
  
      }else if(this.identidad.rol == 'clientetom'){
        this.clientetom = true;
        this.displayedColumnsSearchArticles = ['codArticulo', 'descripcion'];
      }else if(this.identidad.rol == 'vendedor'){
        this.vendedor = true;
        this.displayedColumnsSearchArticles = ['codArticulo', 'descripcion', 'stock','pend'];
      }else {
        this.displayedColumnsSearchArticles = ['codArticulo', 'descripcion', 'proveedor'];
      }
      if(this.clientedc==false && this.clientetom==false && this.vendedor==false){
        this._clientsService.getClientsDepoSoft(this.token).subscribe (
          Response => {
              if (Response.status === 'success') {
                    this.clients1 = Response.data;
                    
              } else {
                console.log(Response);
              }
          },
          error => {
                console.log(error);
          });
  
          this._clientsService.getClientsDurle(this.token).subscribe (
            Response => {
                if (Response.status === 'success') {
                      this.clients2 = Response.data;
                      this.flagclientes = true;
                } else {
                  console.log(Response);
                }
            },
            error => {
                  console.log(error);
            });
          }
  
          if (this.vendedor)
          {  
            this.clients = this._clientsService.getClientesLocal();
            this.order.establishment_id = 1;
            this.depo = !this.depo;
            this.flagclientes = true;
          }
     
      this.observationsFormGroup = this._formBuilder.group({
        cliente_nombre : [''],
        dom_cli : [''],
        tel_cli : [''],
        observacion : [''],
        total: [{value: 0, disabled: true}]
      });

      this.articulosFormGroup = this._formBuilder.group({
        canti : ['', Validators.required],
        descrip : ['', Validators.required],
        punit : ['', Validators.required]
      });
      this.displayedColumnsSearchArticles.push('finalPrice');
            
    }
    get f() { return this.articulosFormGroup.controls; }
    onSubmit() {
      this.submitted = true;
    }
     applyFilter(filterValue: string) {
       if (filterValue !== '') {
            this.dataSourceSearchArticles.filter = filterValue.trim().toLowerCase();
            this.dataSourceSearchArticles.filterPredicate = function(data, filter: string): boolean {
              return   data.codArticulo.toString().includes(filter)
                    || data.fulldescrip.toLowerCase().includes(filter);
          };
        } else {
          this.dataSourceSearchArticles.filteredData = [];
        }
    }
  
    applyFilterBD(filterValue: string) {
      if (filterValue !== '') {
        this._articlesService.getArtsSoftBD(filterValue, this.token).subscribe(
          Response => {      
            this.articles = Response.articulos;
            this.dataSourceSearchArticles = new MatTableDataSource(this.articles);
            // console.log(Response.articulos);
            this.order.establishment_id = 1;
            // this.dataSourceSearchArticles.filteredData = [];
            this.calculateFinalPrices(this.articles,1);
          },
          error => {
            console.log(error);
          });
       } else {
         this.dataSourceSearchArticles.filteredData = [];
       }
   }
  
    calculateFinalPrices(articulos,es:number) {
      //console.log('articulos', articulos);
      for (let i = 0; i < articulos.length; i++) {
        articulos[i].finalPrice = this.getFinalPrice(articulos[i],es);
      }
    }
  
    test(i: number) {
      this.inputCantidad.nativeElement.focus();
      this.selectedArticulo = this.dataSourceSearchArticles.filteredData[i];
       let aux = [];
       aux.push(this.dataSourceSearchArticles.filteredData[i]);
      this.dataSourceSearchArticles.filteredData = aux;
    }
  
     addArticle(cantArticle): void {
       //console.log('cantarti',cantArticle);

       if (cantArticle) {
       if(cantArticle == -5){
        let artix = new ArtiOca(990000,1,this.articulosFormGroup.controls['descrip'].value,this.articulosFormGroup.controls['punit'].value,
        0,0,0);
          // this.selectedArticulo = this.dataSourceSearchArticles.filteredData[0];
          // this.selectedArticulo.fulldescrip = this.articulosFormGroup.controls['descrip'].value;
          // this.selectedArticulo.costo = this.articulosFormGroup.controls['punit'].value;
          // this.selectedArticulo.impuesto = 0;
          // this.selectedArticulo.ganancia = 0;
          // this.selectedArticulo.flete = 0;
          // this.selectedArticulo.codArticulo = 990000;
          this.selectedArticulo = artix;
          cantArticle = this.articulosFormGroup.controls['canti'].value;
          this.cantArticle = this.articulosFormGroup.controls['canti'].value;
          // console.log('saarre',this.selectedArticulo);

       }
       
       let isLoad = false;
      if (this.selectedArticulo) {
        this.articlesSelected.forEach(element => {
            if (this.selectedArticulo.codArticulo!= 990000 && element.codArticulo === this.selectedArticulo.codArticulo) {
                isLoad = true;
            }
        });
  
          if (!isLoad) {
              if (Number(this.cantArticle) !== 0) {
                    this.auxAddArticle();
                    this.finArticulos(cantArticle);
            }
          } else {
            Swal.fire('Cuidado!', 'Este articulo ya esta cargado', 'warning');
          }
        } else {
          Swal.fire('Cuidado!', 'no has seleccionado ningun articulo', 'warning');
        }
      }
    
      
     }
  
     auxAddArticle(): void {
       let vali;
      let article: any = _.clone(this.selectedArticulo);
      if(article.codArticulo == 990000){
      article.rent = 0;
      article.finalPrice = article.costo;
      }
      else
      article.rent = this.rentabilidad;
      if(this.depo || this.clientedc){
      vali = (((article.ganancia * article.costo) / 100) + ((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo )  * this.cantArticle;
      this.total += vali + vali * article.rent/100;
      }else
      this.total += (((article.gananciatom * article.costo) / 100) + ((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo )  * this.cantArticle;
     
      this.discounts = {
        rechargeCC: Number(((15 * this.total) / 100).toFixed(2))
      };
      let artiAux: any = {};
      artiAux.rent = article.rent;
      artiAux.cantidad = this.cantArticle;
      artiAux.deliver = this.cantArticle;
      artiAux.id_articulo = this.selectedArticulo.codArticulo;
      article.cantidad = this.cantArticle; 
      if(this.depo)
      article.precioXuni = ((article.ganancia * article.costo) / 100) + ((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo;
      else if(this.clientedc){
      article.precioXuni = ((article.ganancia * article.costo) / 100) + ((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo;
      article.precioXuni = article.precioXuni + article.precioXuni*article.rent/100;
      }
      else
      article.precioXuni = ((article.gananciatom * article.costo) / 100) + ((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo;
      
      article.disXuni = {        
        CC: Number(((15 * article.precioXuni) / 100).toFixed(2)),
      };
      article.total = article.precioXuni * this.cantArticle;
      article.distotal = {
        CC: Number(((15 * article.total) / 100).toFixed(2)),
      };
      article.stock = this.selectedArticulo.stock;
      artiAux.unitPrice = article.precioXuni;
      artiAux.unitPriceOld = article.precioXuni;
      artiAux.total = article.total;
      artiAux.stock = article.stock;
      artiAux.fulldescrip = article.fulldescrip;
      this.articlesSelected.push(article);
      this.order.orderLine.push(artiAux);
      //console.log('orx',this.articlesSelected,this.order.orderLine);
      this.cantArticle = null;
      this.flag = true;
      this.dataSourceArticulos = new MatTableDataSource(this.articlesSelected);
      //console.log('datasourcearti',this.dataSourceArticulos);
     }
  
     ngOnInit() {
     }

     applyRent(article: any,i:number){
      let vali;
      let artiAux: any = _.clone(this.order.orderLine[i]);
      let arx: any = _.clone(this.order.orderLine[i]);
      // console.log('oti_ant',article.rent,arx.total, vali, this.total);
      this.total -= arx.total;
      vali = (((article.ganancia * article.costo) / 100) + ((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo )  * article.cantidad;
      this.total += vali + vali * article.rent/100;
      // console.log('oti',article.rent,arx.total, vali, this.total);
      this.observationsFormGroup.controls['total'].setValue(Number((this.total).toFixed(2)));
      article.precioXuni = ((article.ganancia * article.costo) / 100) + ((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo;
      article.precioXuni = article.precioXuni + article.precioXuni*article.rent/100;

      article.disXuni = { CC: Number(((15 * article.precioXuni) / 100).toFixed(2)) };
      article.total = article.precioXuni * article.cantidad;
      article.distotal = { CC: Number(((15 * article.total) / 100).toFixed(2)) };
      
      artiAux.rent = article.rent;
      artiAux.total = article.total;
      artiAux.unitPrice = article.precioXuni;
      artiAux.unitPriceOld = article.precioXuni;

      this.articlesSelected.splice(i, 1, article);
      this.order.orderLine.splice(i, 1, artiAux);
      // console.log('oline',this.order.orderLine[i]);
     }
  
     getFinalPrice(article: any,es:number) {
      if(es==1){
        let co =((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo;
   
      return (((article.ganancia * co) / 100)  + co)*1.3;
    }
    else{
      let co =((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo;
  
    return (((article.ganancia * co) / 100)  + co)*1.3;
      }
     }
  
     focusCantidad() {
             this.cantArticle = null;
             this.inputCantidad.nativeElement.focus();
     }
  
     generaPresupuesto() {
            if(this.order.orderLine.length > 0) {            
                this.generarPDF();
            } else {
              Swal.fire('Cuidado', 'No se han cargado articulos en el presupuesto', 'warning');
            }
      }
  
      generarPDF(){
        this.order.total = this.observationsFormGroup.controls['total'].value;
      if(this.selectedClient)
        this.order.selectedClient = true;
      else{
        this.order.selectedClient = false;
        this.order.nombreOcasional = this.NombreOcasional;
      }
        if(this.selectedClient){
          this.order.id_cliente = this.selectedClient.codCliente;
          this.order.discount =   this.selectedClient.boniRecar;
        } else {        
            this.order.id_cliente = 349; 
            this.order.discount =  0;
            this.order.clientDiscount = 0;        
        }
        let i = 0;
        this.order.orderLine.forEach(element => {
          element.unitPrice = this.articlesSelected[i].precioXuni;
          element.total = this.articlesSelected[i].total;
          i++;
        });
        this.order.observacion = this.observationsFormGroup.controls['observacion'].value;
        this.order.cliente_nombre = this.observationsFormGroup.controls['cliente_nombre'].value;
        this.order.dom_cli = this.observationsFormGroup.controls['dom_cli'].value;
        this.order.tel_cli = this.observationsFormGroup.controls['tel_cli'].value;
     
        this.order.pedido = 2;
      //console.log('loqueseven',this.order);
      this._presupService.armarPresupuestoCli(this.token, this.order).subscribe(
      Response => {
                 console.log(Response);
                 if (Response.status === 'success') {
                    window.open(APIURL.urlPublic + 'pdfPresupuestoCli/' + Response.pdfName, '_blank');             
                 }
      },
      error =>{
        console.log(error);
        Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
      });
      let navi = this.router.navigate(['/pages/mostrar-ventas']);
      // console.log('let', navi);
      }
  
     
      deleteOrderLine(i: number) {      
          // tslint:disable-next-line:max-line-length
          // (((this.articlesSelected[i].ganancia * this.articlesSelected[i].costo) / 100) + this.articlesSelected[i].costo)
          // console.log('selected',this.articlesSelected[i]);
          this.total -= this.articlesSelected[i].total;
          this.discounts = {
            rechargeCC: Number(((15 * this.total) / 100).toFixed(2))
          };
          //cerear los descuentos
          this.finArticulos(1);
          /*console.log('articles selected: ',this.articlesSelected);
          console.log('articlesAuxuni: ',this.articlesAuxuni);
          console.log('articlesAuxtot: ',this.articlesAuxtot);*/
          this.articlesSelected.splice(i, 1);
          this.articlesAuxuni.splice(i, 1);
          this.articlesAuxtot.splice(i, 1);
          this.order.orderLine.splice(i, 1);
          this.dataSourceArticulos = new MatTableDataSource(this.articlesSelected);
          // console.log(this.articlesSelected);
  
          if (this.articlesSelected.length === 0) {
            this.flag = false;
          }
      }
  
    
      applyBoniRecar(total:number, discount:number, option:string, r:number) {
        //console.log(isNaN(porcent));     
          //let aux = Number(((porcent * total) / 100).toFixed(2));
          this.observationsFormGroup.controls['total'].setValue(Number((total + discount).toFixed(2)));
  
          /*if (option === 'DISCOUNT') {
            this.DR = Number(((porcent * total) / 100).toFixed(2));
          } else*/ if (option === 'CONTADO') {
            this.articlesSelected.forEach(element => {        
              element.precioXuni = Number(((element.disXuni.C * r) + element.precioXuni).toFixed(2)); 
              element.total = Number(((element.distotal.C * r) + element.total).toFixed(2));
            });
            this.order.discountC = discount;
          } else if (option === 'CORRIENTE') {
            this.articlesSelected.forEach(element => {         
              element.precioXuni = Number(((element.disXuni.CC * r) + element.precioXuni).toFixed(2));            
              element.total = Number(((element.distotal.CC * r) + element.total).toFixed(2));       
            });
            this.order.rechargeCC = discount;
          } else if (option === 'FACTURA') {
            this.articlesSelected.forEach(element => {         
              element.precioXuni = Number(((element.disXuni.R * r) + element.precioXuni).toFixed(2));            
              element.total = Number(((element.distotal.R * r) + element.total).toFixed(2));       
            });
            this.order.discountR = discount;
          }else if (option === 'CANTIDAD') {
            this.articlesSelected.forEach(element => {           
              element.precioXuni = Number(((element.disXuni.Cant * r) + element.precioXuni).toFixed(2));
              element.total = Number(((element.distotal.Cant * r) + element.total).toFixed(2));
            });
            this.order.discountCant = discount;
          } else if (option === 'CARDS') {
            this.articlesSelected.forEach(element => {            
              element.precioXuni = Number(((element.disXuni.Cards * r) + element.precioXuni).toFixed(2));            
              element.total = Number(((element.distotal.Cards * r) + element.total).toFixed(2));
            });
            this.order.discountCards = discount;
          }       
        
      }
    
      insertDetail(detail, id) {
        let p = this.renderer.createElement('p');
        this.renderer.setProperty(p, 'id', id);
        this.renderer.appendChild(p, detail);
        this.renderer.appendChild(document.getElementById('detalle'), p);
      }
    
      OnChange(event: any, option: string) {
  
        if (event === true) {       
  
          if (option === 'CONTADO') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, this.discounts.discountC, 'CONTADO', 1);
             let text = this.renderer.createText('NO Pago de contado: ' + '$ ' + this.order.discountC);
             this.insertDetail(text, 'CONTADO');
          } else if (option === 'CORRIENTE') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, this.discounts.rechargeCC, 'CORRIENTE', 1);
            //let text = this.renderer.createText('Cuenta Corriente ' + '$ ' + this.order.rechargeCC);
            //this.insertDetail(text, 'CORRIENTE');
          } else if (option === 'FACTURA') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, this.discounts.discountR, 'FACTURA', 1);
            let text = this.renderer.createText('NO Ticket Comun ' + '$ ' + this.order.discountR);
            this.insertDetail(text, 'FACTURA');
          }else if (option === 'CANTIDAD') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, this.discounts.discountCant, 'CANTIDAD', 1);
            let text = this.renderer.createText('Compra por mayor: ' + '$ ' + this.order.discountCant);
            this.insertDetail(text, 'CANTIDAD');
          } else if (option === 'CARDS') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, this.discounts.discountCards, 'CARDS', 1);
            let text = this.renderer.createText('Compra con tarjeta: ' + '$ ' + this.order.discountCards);
            this.insertDetail(text, 'CARDS');
          }
        } else {
          /*this.observationsFormGroup.controls['total'].setValue(this.totalAux.pop());
  
          for(let j = this.articlesSelected.length-1; j >= 0 ; j--){
            this.articlesSelected[j].precioXuni = this.articlesAuxuni.pop();
            this.articlesSelected[j].total = this.articlesAuxtot.pop();          
          }
  
          this.contDescuentos--;*/
          // this.total = this.totalAux.pop();
          if (option === 'CONTADO') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, Number(-this.discounts.discountC), 'CONTADO', -1);
            this.order.discountC = 0;
            this.renderer.removeChild(document.getElementById('detalle'), document.getElementById('CONTADO'));
          } else if (option === 'CORRIENTE') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, Number(-this.discounts.rechargeCC), 'CORRIENTE', -1);
            this.order.rechargeCC = 0;
            this.renderer.removeChild(document.getElementById('detalle'), document.getElementById('CORRIENTE'));
          } else if (option === 'FACTURA') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, Number(-this.discounts.discountR), 'FACTURA', -1);
            this.order.discountR = 0;
            this.renderer.removeChild(document.getElementById('detalle'), document.getElementById('FACTURA'));
          }else if (option === 'CANTIDAD') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, Number(-this.discounts.discountCant), 'CANTIDAD', -1);
            this.order.discountCant = 0;
            this.renderer.removeChild(document.getElementById('detalle'), document.getElementById('CANTIDAD'));
          } else if (option === 'CARDS') {
            this.applyBoniRecar(this.observationsFormGroup.controls['total'].value, Number(-this.discounts.discountCards), 'CARDS', -1);
            this.order.discountCards = 0;
            this.renderer.removeChild(document.getElementById('detalle'), document.getElementById('CARDS'));
          }
        }
      }
  
      hideOcasional() {
        this.ocasional = !this.ocasional;
      }
     
      hidedurle() {
        this.calculateFinalPrices(this.articles,2);
        if(this.depo){
          this.depo = !this.depo;
          this.clients = [];
         this.selectedClient = null;
        }      
       
        this.clients = this.clients2;
        this.order.establishment_id = 2;
        this.durle = !this.durle;
      }
      hidedepo() {
        this.calculateFinalPrices(this.articles,1);
        if(this.durle){
          this.durle = !this.durle;
          this.clients = [];
          this.selectedClient = null;
        }      
         
        this.clients = this.clients1;
        this.order.establishment_id = 1;
        this.depo = !this.depo;
      }
  
      finArticulos(cantArticle){
        if (cantArticle) {
          if(this.finarti == false) this.finarti = true;
        //console.log('entro: ', this.finarti);

        if(document.getElementById('CORRIENTE'))
        this.renderer.removeChild(document.getElementById('detalle'), document.getElementById('CORRIENTE'));

        let i = 0;
        this.articlesSelected.forEach(element => {           
          element.precioXuni = this.order.orderLine[i].unitPrice;
          element.total = this.order.orderLine[i].total;
          i++;
        });

        this.order.rechargeCC = 0;

        this.contDescuentos = 0;
        this.observationsFormGroup = this._formBuilder.group({
          cliente_nombre : [''],
          dom_cli : [''],
           tel_cli : [''],
          observacion : [''],
          total: [{value: this.total, disabled: true}]
        });      
        this.articulosFormGroup = this._formBuilder.group({
          canti : ['', Validators.required],
          descrip : ['', Validators.required],
          punit : ['', Validators.required]
        });
        //console.log('total',this.observationsFormGroup.controls['total'].value);
        //this.observationsFormGroup.controls['total'].setValue(this.total);
      }
    } 
  }
  