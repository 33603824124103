import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

// Modules
import { PagesModule } from './pages/pages.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ServiceModule } from './services/service.module';
import { LoginModule } from './login/login.module';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { Ng5SliderModule } from 'ng5-slider';

// Rutas
import { APP_ROUTES } from './app.routes';

// Componentes
import { AppComponent } from './app.component';
// import { IntercomModule } from 'ng-intercom';



@NgModule({
  declarations: [
    AppComponent,
  ],
  imports: [
    LoginModule,
    BrowserModule,
    APP_ROUTES,
    PagesModule,
    FormsModule,
    ReactiveFormsModule,
    ServiceModule,
    SlickCarouselModule,
    Ng5SliderModule,
    // IntercomModule.forRoot({
    //   appId: "h609sw2a", // from your Intercom config
    //   updateOnRouterChange: true // will automatically run `update` on router event changes. Default: `false`
    // })
  ],
  providers: [],
  bootstrap: [AppComponent]
})
export class AppModule { }
