import { Injectable } from '@angular/core';
import { APIURL } from '../apiUrl';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Observable } from 'rxjs/internal/Observable';

@Injectable({
  providedIn: 'root'
})
export class VisitasService {

  url: string;

  constructor(public _http: HttpClient) {
    this.url = APIURL.url;
  }

  loadVisits(val:number, token): Observable<any> {
    
    let json = JSON.stringify(val);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Authorization', token)
                                  .set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.post(this.url + 'loadVisits' , params, {headers: headers});
  }

  deleteVisits(val:number, token): Observable<any> {
    
    let json = JSON.stringify(val);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Authorization', token)
                                  .set('Content-type', 'application/x-www-form-urlencoded');

    return this._http.post(this.url + 'deleteVisits' , params, {headers: headers});
  }
}
