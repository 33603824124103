import { Component, OnInit, Renderer2, ElementRef, Input, ViewChild } from '@angular/core';
// Servicios
import { ClientesService } from '../../../services/clientes/clientes.service';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { ArticulosService } from '../../../services/articulos/articulos.service';
import { VentasService } from '../../../services/ventas/ventas.service';
import { VendedoresService } from '../../../services/vendedores/vendedores.service';
// Modelos
import { Order } from '../../../models/order';
import { Cliente } from '../../../models/clientes';
import { Usuario } from '../../../models/usuario';
import { DatePipe } from '@angular/common';
// Angular material
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { MatTableDataSource } from '@angular/material/table';

// LOADASH
import * as _ from 'lodash';
// SweetAlert
import Swal from 'sweetalert2';
import { EstablishmentsService } from '../../../services/establishments/establishments.service';
import { Establishment } from '../../../models/establishment';

import { FormGroup, FormBuilder } from '@angular/forms';

import { APIURL } from '../../../services/apiUrl';
import { Router } from '@angular/router';
import { PresupuestosService } from '../../../services/presupuestos/presupuestos.service';
import { OrderLine } from 'src/app/models/orderLine';

@Component({
  selector: 'app-cart',
  templateUrl: './cart.component.html',
  styleUrls: ['./cart.component.css'],
  providers: [DatePipe, MatDialog]
})
export class CartComponent implements OnInit {

  @ViewChild('cantidad', { static: false }) inputCantidad: ElementRef;

  total: number = 0;
  // Total
  totalAux: number[] = [];
  articlesAuxuni: number[] = [];
  articlesAuxtot: number[] = [];

  order: any;
  aux: any = null;
  hideInv: boolean = false;
  ocasional: boolean = false;
  finarti: boolean = false;
  contDescuentos:number = 0;
  NombreOcasional:string;

  observationsFormGroup: FormGroup;

   // Descuentos
   discounts: any = {
    discountR: 0,
    discountC: 0,
    rechargeCC: 0,
    discountCant: 0,
    discountCards: 0
  };

  depo:boolean = false;
  durle: boolean =false;

  // mat table
  dataSource = new MatTableDataSource<any>([]);
  dataSourceOrder = new MatTableDataSource<any>([]); 
  dataSourceSearchOrder = new MatTableDataSource<any>([]);
  dataSourceFastSearch = new MatTableDataSource<any>([]);
  dataSourceSearchArticles = new MatTableDataSource<any>([]);
  displayedColumnsOrder: string[] = ['descripcion', 'cantidad', 'total', 'acciones'];
  displayedColumnsSearchOrder: string[] = ['imagen','descripcion', 'cantidad', 'total', 'acciones'];

  clients: Cliente[];
  clients1: Cliente[];
  clients2: Cliente[];
  selectedClient: any = "Cliente Presupuesto";
  cantArticle: number;
  flag: boolean = false;
  flag2: boolean = false;
  flagclientes: boolean = false;
  flagarticles: boolean = false;
  establishments: Establishment[] = [];
  status: boolean = false;
  articles: any[];
  articlesSelected: any[] = [];
  // artiAux: any = {};
  selectedArticulo: any = null;
  artdataSource: any[] = [];
  token: string;
  identidad: any;
  ordercliente: number;
  establishment_id: number;
  cliente: any;
  clientedc:boolean = false;
  clientetom:boolean = false;
  presupuesto: any;
  presupuestoAux: any[] = [];
  paginator: any;
  sort: any; 
  displayedColumnsSearchArticles: string [];
  displayedColumnsFastSearch: string [];
  dataSourceArticulos: MatTableDataSource<any>;
  url:string;
  orderlineAux: any[] = [];
  clientes: any[] = [];
  seller: any;


  constructor( private _clientsService: ClientesService, private _userService: UsuarioService,
               private _articlesService: ArticulosService, private renderer: Renderer2, private router: Router,
               private _ventasService: VentasService, private datepipe: DatePipe, public dialog: MatDialog,
               private _establishmentsService: EstablishmentsService, private _presupService: PresupuestosService,
               private _vendedoresService: VendedoresService, private _formBuilder: FormBuilder ) {

    this.order = new Order(null, null, null, null, [], null, null, null, null);
    this.order.fecha = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    this.order.dateTime = this.datepipe.transform(new Date(), 'yyyy-MM-ddThh:mm:ss');
    this.order.user_id = this._userService.getIdentidad().sub;
    this.token = _userService.getToken();
    this.establishment_id = _userService.getIdentidad().establi;
    this.observationsFormGroup = this._formBuilder.group({
      checkedCorriente: [null],
    }); 
    this.url = APIURL.urlPublic;
    this.identidad = _userService.getIdentidad();
    console.log(this.identidad);

    if (this.identidad.rol==='vendedor')
    {
      this._vendedoresService.getSellerUser(this.identidad.sub, this.token).subscribe(
        Response => {
          this.seller = Response.data[0];
          this._clientsService.getSellerClients(this.token, this.seller).subscribe(
            Response => {
              if (Response.status === 'success') {
                this.clientes = Response.data;
                console.log('clientes del loco', this.clientes);
              } else {
                console.log(Response.message);
              }
            },
            error => {
              console.log('error: ', error);
          });
        },
        error => {
          console.log('error: ', error);
        });  
    }
    

    this._clientsService.getUserIDClients(this.token, this.identidad.sub).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.cliente = Response.data[0];
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
    });
   
    this.displayedColumnsSearchOrder = ['imagen', 'descripcion', 'cantidad', 'total', 'acciones'];
    this.displayedColumnsSearchArticles = ['descripcion'];
    

    this._articlesService.obtenerArticulos(this.token).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.articles = Response.articulos;
          this.dataSourceSearchArticles = new MatTableDataSource(this.articles);
          this.dataSourceSearchArticles.filteredData = [];
          this.flagarticles = true;
          this.calculateFinalPrices(this.articles);
          this.order.establishment_id = 1;
          this.flagclientes = true;
        } 
      },
      error => {
        console.log(error);
      });
    
    this.displayedColumnsSearchArticles.push('finalPrice');
    
    //Obtengo Datos de usuario desde DB para traer pedido 
    this._userService.getUser(this.identidad.sub, this.token).subscribe(
      Response => {

        // Obtengo Pedido
        this._presupService.getPresupuesto(this.token, Response.user.id_presupuesto).subscribe(
          Response => {
            if (Response.status === 'success') {
              this.order = Response.order;
              this.dataSourceSearchOrder = new MatTableDataSource(this.order);
              this.dataSourceSearchOrder.paginator = this.paginator;
              this.dataSourceSearchOrder.sort = this.sort;
              this.flag = true;
              this.flag2 = true;
              this.armarPresupuestoDisplay(this.dataSourceSearchOrder.data);
            } 
          },
          error => {
            console.log(error);
            this.flag2 = true;
          });
          //this.displayedColumnsSearchArticles.push('finalPrice');   
      
      },
      error => {
        console.log(error);
      }
      );

      this._ventasService.getMejoresArticulosAll(this.token).subscribe(
        Response => {
          //console.log('Response: ', Response);
          if (Response.status === 'success') {
            this.calculateFinalPrices(Response.data);
            this.artdataSource = Response.data;
            console.log('mejores:',Response.data);
          }
        },
        error => {
          console.log('error: ', error);
        });
             
   }

   armarPresupuestoDisplay(presuDB:any){
      this.presupuesto = presuDB;
      this.presupuestoAux = _.cloneDeep(this.presupuesto);
      //console.log('lo tengo:', this.presupuesto.orderLines);
      //console.log(this.dataSourceSearchOrder);
      this.presupuesto.orderLines.forEach(element => {
        this.total += element.total;
      });
      console.log('lo tengo:', this.presupuesto.orderLines);
   }

   


  applyFilter(filterValue: string) {
    if(filterValue.length > 2){
      if (filterValue !== '') {
          this.dataSourceSearchArticles.filter = filterValue.trim().toLowerCase();
          this.dataSourceSearchArticles.filterPredicate = function(data, filter: string): boolean {
            return   data.codArticulo.toString().includes(filter)
                  || data.fulldescrip.toLowerCase().includes(filter)
                  || data.ganancia.toString().includes(filter)
                  || data.category.category.toLowerCase().includes(filter)
                  || data.proveedor.descripcion.toLowerCase().includes(filter);
        };
      } else {
        this.dataSourceSearchArticles.filteredData = [];
        }
    }
  }

  test(i: number) {
    this.selectedArticulo = this.dataSourceSearchArticles.filteredData[i];
    this.orderlineAux.push(this.selectedArticulo);
    this.orderlineAux[0].unitPrice = this.selectedArticulo.finalPrice;
    this.orderlineAux[0].id_articulo = this.selectedArticulo.codArticulo;
    this.orderlineAux[0].cantidad = 1;
    this.orderlineAux[0].total = this.selectedArticulo.finalPrice; 
    this._presupService.addOrderLine(this.token, this.presupuesto.id, this.orderlineAux).subscribe(
    Response => {
             console.log(Response);
    },
    error =>{
      console.log(error);
      Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
    });
    this._presupService.getPresupuesto(this.token, this.presupuesto.id).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.order = Response.order;
          this.dataSourceSearchOrder = new MatTableDataSource(this.order);
          this.dataSourceSearchOrder.paginator = this.paginator;
          this.dataSourceSearchOrder.sort = this.sort;
          this.presupuesto = [];
          this.presupuesto = this.dataSourceSearchOrder.data;
          this.presupuestoAux = _.cloneDeep(this.presupuesto);
          this.flag = true;
          this.total = 0;
          this.presupuesto.total=0;
          this.presupuesto.orderLines.forEach(element => {
            this.total += element.total;
          });
          this.presupuesto.total=this.total;
          document.getElementById('totalDisplay').focus();
          this.dataSourceSearchArticles.filteredData  = [];
        } 
      },
      error => {
        console.log(error);
      });
  }

   ngOnInit() {
   }


  calculateFinalPrices(articulos) {
    for (let i = 0; i < articulos.length; i++) {
      articulos[i].finalPrice = this.getFinalPrice(articulos[i]);

    }
  }
  
  getFinalPrice(article: any) {

      let co =((article.impuesto * article.costo) / 100) + ((article.flete * article.costo) / 100) + article.costo;
 
    return (((article.ganancia * co) / 100)  + co)*1.3;
 
   }

   focusCantidad() {
           this.cantArticle = null;
           this.inputCantidad.nativeElement.focus();
   }

    deleteOrderLine(i: number) {      
        
        this.total -= this.presupuesto.orderLines[i].total;
        this.presupuesto.total -= this.presupuesto.orderLines[i].total;
        this._presupService.deleteOrderLine(this.token, this.order.id, this.presupuesto.orderLines[i]).subscribe(
          Response => {
            console.log(Response);
            this.presupuesto.orderLines.splice(i, 1);
            this.observationsFormGroup = this._formBuilder.group({
              checkedCorriente: [null]
            });
            this._presupService.getPresupuesto(this.token, this.presupuesto.id).subscribe(
              Response => {
                if (Response.status === 'success') {
                  this.order = Response.order;
                  this.dataSourceSearchOrder = new MatTableDataSource(this.order);
                  this.dataSourceSearchOrder.paginator = this.paginator;
                  this.dataSourceSearchOrder.sort = this.sort;
                  this.presupuesto = [];
                  this.presupuesto = this.dataSourceSearchOrder.data;
                  this.presupuestoAux = _.cloneDeep(this.presupuesto);
                  this.flag = true;
                  // cerea los totales y cargarlos con foreach
                  this.total = 0;
                  this.presupuesto.total=0;
                  this.presupuesto.orderLines.forEach(element => {
                    this.total += element.total;
                  });
                  this.presupuesto.total=this.total;
                  if (this.order.orderLines.length === 0) {
                    this.flag = false;
                    this._userService.removePresup(this.identidad.sub, this.token).subscribe(
                      Response => {
                        console.log(Response);
                      },
                      error => {
                        console.log(error);
                      });
                  }
                  this.ngOnInit();
                } 
              },
              error => {
                console.log(error);
              });


          },
          error => {
            console.log(error);
          });
    }

    insertDetail(detail, id) {
      let p = this.renderer.createElement('p');
      this.renderer.setProperty(p, 'id', id);
      this.renderer.appendChild(p, detail);
      this.renderer.appendChild(document.getElementById('detalle'), p);
    }


    saveValue(article, i) {
          this.presupuesto.orderLines[i].cantidad = article.cantidad;
          this.total -= article.total;
          this.presupuesto.orderLines[i].total = (this.presupuesto.orderLines[i].unitPrice * article.cantidad);
          this.dataSourceSearchOrder = new MatTableDataSource(this.presupuesto);
          this.dataSourceSearchOrder.paginator = this.paginator;
          this.dataSourceSearchOrder.sort = this.sort;
          this.dataSourceSearchOrder._updateChangeSubscription();
          this.total += this.presupuesto.orderLines[i].total;
          this.presupuesto.total = 0;
          this.presupuesto.orderLines.forEach(element => {
            this.presupuesto.total += element.total;
          });
      }


      generaPresupuesto() {
        this.presupuesto.discount =  0;
        this.presupuesto.clientDiscount = 0;
        this.presupuesto.discountC = 0;
        this.presupuesto.discountR = 0;
        this.presupuesto.rechargeCC = 0;
        this.presupuesto.discountCant = 0;
        this.presupuesto.discountCards = 0;
        this.presupuesto.establishment_id = 1;
        this.presupuesto.pedido = 1;
        this.presupuesto.fecha = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
        this.presupuesto.orderLine = this.presupuesto.orderLines; 
        this.presupuesto.selectedClient = this.cliente;
        if(this.presupuesto.rechargeCC>0)
        this.presupuesto.observacion = 'Lista de precios Cuenta Corriente';
      else
        this.presupuesto.observacion = 'Lista de precios Contado';
        console.log(this.presupuesto);
        this._presupService.armarPresupuesto(this.token, this.presupuesto).subscribe(
          Response => {
            console.log(Response);
            if (Response.status === 'success') {
          
              if(this.order.establishment_id == 1)
               window.open(APIURL.urlPublic + 'pdfPresupuesto/' + Response.pdfName, '_blank');
              else
               window.open(APIURL.urlPublic + 'pdfPresupuestoTOM/' + Response.pdfName, '_blank');
              Swal.fire({
                position: 'center',
                type: 'success',
                title: 'El pedido fue enviado a nuestra sucursal con éxito',
                showConfirmButton: false,
                timer: 1500
              });

              this._userService.removePresup(this.identidad.sub, this.token).subscribe(
                Response => {
                  console.log(Response);
                },
                error => {
                  console.log(error);
                });
              
              let navi = this.router.navigate(['/pages/cards-articulos']);
            }
          },
          error =>{
            console.log(error);
            Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
          });
      }

      OnChange(event: any, option: string) {

        if (event === true) {       
          this.presupuestoAux = _.cloneDeep(this.presupuesto);
          if (option === 'CORRIENTE') {
            this.applyBoniRecar('CORRIENTE', 1);
          } 
        } else {
            if (option === 'CORRIENTE') {
              this.applyBoniRecar('CORRIENTE', -1);
              this.presupuesto.rechargeCC = 0;
            } 
          }
      }

      applyBoniRecar( option:string, r:number) {
          
          if (option === 'CORRIENTE') {
            this.total = 0;
            if(r==1){
              this.presupuesto.orderLines.forEach(element => {         
                element.unitPrice = Number(((element.unitPrice * 0.15 * r) + element.unitPrice).toFixed(2));          
                element.total = Number(((element.total * 0.15 * r) + element.total).toFixed(2));      
                this.total += element.total; 
              });
              this.presupuesto.rechargeCC = Number((this.total * 0.15 * r).toFixed(2));
              this.presupuesto.total=this.total;
              this.dataSourceSearchOrder = new MatTableDataSource(this.presupuesto);
            }
            else{
              this.presupuesto = this.presupuestoAux;
              this.presupuesto.orderLines.forEach(element => {               
                this.total += element.total; 
              });
              this.presupuesto.rechargeCC = 0;
              this.presupuesto.total = this.total;
              this.dataSourceSearchOrder = new MatTableDataSource(this.presupuesto);
            }
          }        
        
      }


      //Funciones Slider

      slideConfig = {
        "slidesToShow": 6,
        "slidesToScroll": 4,
        "infinite": false
      };

      slickInit(e:any) {
        console.log('slick initialized');
      }

      breakpoint(e:any) {
        console.log('breakpoint');
      }

      afterChange(e:any) {
        console.log('afterChange');
      }

      beforeChange(e:any) {
        console.log('beforeChange');
      }
            

       
  
}
