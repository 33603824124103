import { Component, OnInit, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import Swal from 'sweetalert2';
import { UsuarioService } from 'src/app/services/services.index';

@Component({
  selector: 'app-dialog-changepw',
  templateUrl: './dialog-changepw.html',
  styleUrls: ['./dialog-changepw.css']
})
export class DialogChangepwComponent implements OnInit {

  email: string;
  title: string;
  constructor(private _userService: UsuarioService, public dialogRef: MatDialogRef<DialogChangepwComponent>, @Inject(MAT_DIALOG_DATA) public data: any) {
    this.email = null;
    this.title = 'Ingrese su correo';

    if (this.data.deliver) {
      this.title += ' Su correo registrado';
    }
  }

  ngOnInit() {
  }

  save() {

    this._userService.recoverPass(this.email).subscribe(
      Response => {
       console.log(Response);
       if (Response.status === 'success') {
        Swal.fire('Exito', 'El enlace para restablecer su contraseña fue enviado a su dirección de correo electrónico', 'success');
       } else {
         Swal.fire('Cuidado!', 'Hubo un error al enviar el correo de recuperación', 'warning');
         console.log(Response.message);
         }
       },
      error => {
         console.log('error: ', error);
     });
   }

}
