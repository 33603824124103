import { Component, OnInit, HostListener } from '@angular/core';
// MODELOS
import { Usuario } from '../../models/usuario';

// SERVICIOS
import { UsuarioService } from '../../services/usuario/usuario.service';

// ROUTER
import { Router } from '@angular/router';
import { CategoriesService } from 'src/app/services/categories/categories.service';
import { ArticulosService } from 'src/app/services/articulos/articulos.service';
// import { Intercom } from 'ng-intercom';
import Swal from 'sweetalert2';
import { APIURL } from '../../services/apiUrl';
import { ExcelService } from '../../services/excel/excel.service';



@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styles: [],
  providers: [ExcelService]
})

export class HeaderComponent implements OnInit {
  token: string;
  identidad: any;
  pdfCatalogo: string;
  usuario: any;
  flag: boolean;
  categories: string;
  cantArt:number;
  categ:number = 999;
  catestr:string = '';
  searchTerm:string = '';
  p: number = 1;
  // articulos obtenidos
  articulos: any[] = [];
  filteredArticulos: any[] = [];
  hidecat: boolean=true;
 
  loading:boolean = false;
 
  // Bandera
  dataReady: boolean = false;


  constructor( private _usuariosServices: UsuarioService, private categoriesService: CategoriesService, 
    private _articulosService: ArticulosService, private _router: Router, 
    // public intercom: Intercom, 
    private _excelService: ExcelService) {
     
    this.usuario = _usuariosServices.getIdentidad();
    //console.log('usuario: ', this.usuario);
    this.flag = false;
    this.token = _usuariosServices.getToken();
  }

    logout() {
      // this.intercom.shutdown();
      localStorage.removeItem('token');
      localStorage.removeItem('identidad');
      this._router.navigate(['/login']);
    }
    
  ngOnInit() {
  }

  searchItem(busqueda: string){
    this._router.navigate(['/pages/cards-articulos-filtered', busqueda]);
  }



  busquedaCateg(cat: number, catestr:string) {
    this.flag = false;
    this.catestr = catestr;
    this._articulosService.getArtTermCatID( "" , 1 , cat , this.token).subscribe(
      Response => {         
         this.calculateFinalPrices(Response.articulos);
         this.filteredArticulos = Response.articulos;
         // Mandar filteredArticulos y Cantart a cards-articulos
         this.cantArt = Response.cant;
         console.log(this.filteredArticulos);
         console.log(this.cantArt);
         this.flag = true;
      },
      error => {        
        console.log(error);
      });
    this.searchTerm = "";
    this.categ = cat;
    this.p = 1;
  }

  calculateFinalPrices(articulos) {
    for (let i = 0; i < articulos.length; i++) {
      articulos[i].precioContado = this.getFinalPrice(articulos[i].ganancia, articulos[i].impuesto, articulos[i].costo, articulos[i].flete);
      articulos[i].precioCtaCte = articulos[i].precioContado*1.3;
    }
  }

  getFinalPrice(ganancia = 0, impuesto = 0, costo = 0, flete = 0): number {          
    let x1 = ganancia/100;
    let x2 = costo;
    let x3 = flete/100;
    let x4 = impuesto/100;    

    return ((x1 * x2) + (x3 * x2) + (x4 * x2) + x2); 
  }

  open(){
      let elem: HTMLElement = document.getElementById('megamenu') as HTMLElement;
      elem.click();
  }

  catalogo(){
    this.loading =true;
    this.pdfCatalogo = APIURL.urlPublic + 'pdfCatalogo/pdf' + + '.pdf';
    this._articulosService.armarCatalogo(this.token).subscribe(
      Response => {      
                 console.log(Response);
                 if (Response.status === 'success') {
                  window.open(APIURL.urlPublic + 'pdfCatalogo/' + Response.pdfName, '_blank');
                  this.loading =false;
                 }
      },
      error =>{
        console.log(error);
        this.loading =false;
        Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
      });
  }
  
  exportAsXLSX():void {
    let artxls:any []=[];
    this.loading = true;
    this._articulosService.obtenerPreciosXLSX(this.token).subscribe(
      Response => {      
         artxls = Response.articulos;            
          this._excelService.exportAsExcelFile(artxls, 'Lista de precios');
          this.loading = false;
      },
      error => {        
        console.log(error);
        this.loading = false;
      }); 
    
  }


}
