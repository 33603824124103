import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { Usuario } from '../../../models/usuario';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { SelectionModel } from '@angular/cdk/collections';
import { ClientesService } from '../../../services/clientes/clientes.service';
import { VendedoresService } from '../../../services/vendedores/vendedores.service';
import { VisitasService } from '../../../services/visitas/visitas.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-visitas',
  templateUrl: './visitas.component.html',
  styleUrls: ['./visitas.component.css']
})
export class VisitasComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('matTable', { static: false }) elementView: ElementRef;
  itemPerPage = 20;
  position = 0;
  limitArray = 0;
  yOffset = 400;
  seller:any;
  flag = false;
  token: string;
  identidad: any;
  dataSource = new MatTableDataSource<any>([]);
  loading:boolean = false;

  checkNoVisit: boolean;
  checkVisit: boolean;
  checkTodas: boolean = true;

  sizeOptions = [30, 100];

  displayedColumns: string[] = ['cliente', 'localidad','calif_pago','objetivo','visita1','visita2','visita3','visita4'];

  constructor(private _userService: UsuarioService, private _clientesServices: ClientesService, 
    private _vendededoresService: VendedoresService, private _visitasService: VisitasService) {
    this.token = _userService.getToken();
    this.identidad = _userService.getIdentidad();

    this._vendededoresService.getSellerUser(this.identidad.sub, this.token).subscribe(
      Response => {
        this.seller = Response.data[0];
       
        this._clientesServices.getClientsForVisits(this.token, this.seller.user_id).subscribe(
          (Response:any) => {
          
           this.dataSource = new MatTableDataSource(Response.data);
           this.dataSource.sort = this.sort;
         this.dataSource.paginator = this.paginator;
          this.flag =true;
          },
          error => {
            console.log('error: ', error);
          });
      },
      error => {
        console.log('error: ', error);
      });
    
   
   }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return  data.nombre.toLowerCase().includes(filter);    
  };
  }

  selectArticle(event,cod) {
    if(event.checked == true){
    this._visitasService.loadVisits(cod,this.token).subscribe(
      Response => {
        if (Response.status === 'success') {
        console.log('ok',Response);
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
      }
    );
  }else{

    Swal.fire({title: 'Desea eliminar esta visita?',type: 'question',showCancelButton: true})
    .then ((result) => {
      if (result.value) {
        this._visitasService.deleteVisits(cod,this.token).subscribe(
          Response => {
            if (Response.status === 'success') {
            console.log('ok',Response);
            } else {
              console.log(Response.message);
            }
          },
          error => {
            console.log('error: ', error);
          }
        );
      }          
   });  

  }
  }

  statusCheckNoVisit() {
    if (this.checkNoVisit) {
        this.checkVisit = false;
        this.checkTodas = false;
    }
    this.clearDataTable();
    this.getClientsNoVisit();
  }

  statusCheckVisit() {
    if (this.checkVisit) {
      this.checkNoVisit = false;
      this.checkTodas = false;
    }
    this.clearDataTable();
    this.getClientsVisit();
  }

  statusCheckTodas() {
    if (this.checkTodas) {
      this.checkVisit = false;
      this.checkNoVisit = false;
    }
    this.clearDataTable();
    this.getClients();
  }

  clearDataTable() {
    this.dataSource.data = [];
  }

  getClientsNoVisit(){
    this._clientesServices.getClientsForVisits2(this.token, this.seller.user_id).subscribe(
      (Response:any) => {

       this.dataSource = new MatTableDataSource(Response.data);
       this.dataSource.sort = this.sort;
     this.dataSource.paginator = this.paginator;
      this.flag =true;
      },
      error => {
        console.log('error: ', error);
      });
  }

  getClientsVisit(){
    this._clientesServices.getClientsForVisits1(this.token, this.seller.user_id).subscribe(
      (Response:any) => {
     
       this.dataSource = new MatTableDataSource(Response.data);
       this.dataSource.sort = this.sort;
     this.dataSource.paginator = this.paginator;
      this.flag =true;
      },
      error => {
        console.log('error: ', error);
      });
  }

  getClients(){
    this._clientesServices.getClientsForVisits(this.token, this.seller.user_id).subscribe(
      (Response:any) => {
       
       this.dataSource = new MatTableDataSource(Response.data);
       this.dataSource.sort = this.sort;
     this.dataSource.paginator = this.paginator;
      this.flag =true;
      },
      error => {
        console.log('error: ', error);
      });    
  }


}
