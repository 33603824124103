export class Usuario {

    public id: number;
    public nya: string;
    public email: string;
    public rol: string;
    public cliente: string;
    public password: string;
    public gettoken: string;
    public estid:number;

    constructor(id: number, email: string, rol: string, cliente: string, password: string, nya: string, estid: number) {
        this.id = id;
        this.email = email;
        this.rol = rol;
        this.cliente = cliente;
        this.password = password;
        this.gettoken = 'false';
        this.nya = nya;
        this.estid = estid;
    }
}
