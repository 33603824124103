export class Pago {

    public id: number;
    public recibo_id: number;
    public order_id: number;
    public amount: number;
    public descuento: number;   

    constructor(id: number,recibo_id: number, order_id: number, amount: number, descuento: number) {
        this.id = id;
        this.recibo_id = recibo_id;
        this.order_id = order_id;
        this.amount = amount;
        this.descuento = descuento;        
    }
}
