import { OrderLine } from './orderLine';
export class Order {

    public idOrder: number;
    public fecha: string;
    public id_cliente: number;
    public user_id: number;
    public orderLine: any[];
    public observacion: string;
    public dateTime: string;
    public saldo: number;
    public total: number;


    constructor(idOrder: number, fecha: string, id_cliente: number, user_id: number, orderline: any, observacion: string, dateTime: string, saldo: number , total: number) {
        this.idOrder = idOrder;
        this.user_id = user_id;
        this.fecha = fecha;
        this.id_cliente = id_cliente;
        this.orderLine = orderline;
        this.observacion = observacion;
        this.dateTime = dateTime;
        this.saldo = saldo;
        this.total = total;
    }
}
