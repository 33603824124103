export class ArtiOca {

    public codArticulo: number;
    public cantidad: number;
    public fulldescrip: string;    
    public costo: number;    
    public flete: number;
    public impuesto: number;
    public ganancia: number;

    constructor(codArticulo: number, cantidad: number, fulldescrip: string,
        costo: number, flete: number, impuesto: number, ganancia: number) {

        this.codArticulo = codArticulo;
        this.cantidad = cantidad;
        this.fulldescrip = fulldescrip;      
        this.costo = costo;
        this.flete = flete;
        this.impuesto = impuesto;
        this.ganancia = ganancia;        
    }
}
