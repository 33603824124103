export class Cheque {

    public id: number;
    public number: number;
    public cliente_id: number;
    public pago_id: number;
    public bank: string;
    public amount: number;
    public start_date: string;
    public end_date: string;
    public state: string;// states: pendiente, cobrado, sin fondo, vencido. Default(pendiente)

    constructor(id: number, number: number, cliente_id: number, pago_id: number, bank: string, amount: number, start_date: string, end_date: string, state: string) {
        this.id = id;
        this.number = number;
        this.cliente_id = cliente_id;
        this.pago_id = pago_id;
        this.bank = bank;
        this.amount = amount;
        this.start_date = start_date;
        this.end_date = end_date;
        this.state = state;
    } 
}
