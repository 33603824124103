import { Component, OnInit, HostListener } from '@angular/core';
import { ArticulosService } from '../../../services/articulos/articulos.service';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { Router } from '@angular/router';
import { Articulo } from '../../../models/articulo';
import * as _ from 'lodash';
import { APIURL } from '../../../services/apiUrl';
import Swal from 'sweetalert2';
import {FlatTreeControl} from '@angular/cdk/tree';
import {MatTreeFlatDataSource, MatTreeFlattener} from '@angular/material/tree';
import { CategoriesService } from '../../../services/categories/categories.service';
import { VentasService } from 'src/app/services/ventas/ventas.service';
import { MatTableDataSource } from '@angular/material/table';
import { SlickCarouselComponent } from 'ngx-slick-carousel';
import { PresupuestosService } from 'src/app/services/presupuestos/presupuestos.service';
import { ClientesService } from 'src/app/services/clientes/clientes.service';
import { Order } from 'src/app/models/order';
import { DatePipe } from '@angular/common';
import { ExcelService } from '../../../services/excel/excel.service';
import {SelectionModel} from '@angular/cdk/collections';


interface CategoryNode {
  id: number;
  parent_id: number;
  name: string;
  children?: CategoryNode[];
}

interface FlatNode {
  expandable: boolean;
  name: string;
  id: number;
  level: number;
}


@Component({
  selector: 'app-cards-articulos',
  templateUrl: './cards-articulos.component.html',
  styleUrls: ['./cards-articulos.component.css'],
  providers: [ExcelService, DatePipe]
})
export class CardsArticulosComponent implements OnInit {
 
  token: string;
  identity: any;
  flag: boolean;
  private _searchTerm: string;
  p: number = 1;
  cantArt:number;
  categ:number = 999;
  pdfCatalogo: string;
  categories: string;
  activeNode : any = null;

  searchTerm:string = '';
  catestr:string = '';
    
  // articulos obtenidos
   articulos: any[] = [];
   filteredArticulos: any[] = [];
  hidecat: boolean=true;
  artdataSource: any[] = [];
  obradataSource: any[] = [];
  herramientasdataSource: any[] = [];
  loading:boolean = false;
  selection = new SelectionModel<any>(true, []);
  index: number = 1;

  // Bandera
  dataReady: boolean = false;
  flagbusqueda: boolean = false;
  flagbuscado: boolean = false;

  // Cart
  articulo: Articulo;
  order: any;
  auxOrder: any;
  flagpresupuesto: any;
  dataSourceSearchOrder: any;
  paginator: any;
  sort: any;
  costofinal: number;
  cliente: any;



  // Arreglo imágenes carousel

  imagesArray = [
    {name: 'Construcción Tradicional', categ: '1' , url: 'images/categories/contradicional.jpg'},
    {name: 'Construcción en Seco', categ: '2', url: 'images/categories/conseco.jpg'},
    {name: 'Metalúrgica', categ: '3', url: 'images/categories/metalurgica.jpg'},
    {name: 'Aislantes y Membranas', categ: '4', url: 'images/categories/aislantes.jpg'},
    {name: 'Ferretería', categ: '5', url: 'images/categories/ferreteria.jpg'},
   /*  {name: 'Plomería', categ: '6', url: 'images/categories/plomeria.jpg'}, */
    {name: 'Sanitarios', categ: '7', url: 'images/categories/sanitarios.jpg'},
/*     {name: 'Cocinas', categ: '8', url: 'images/categories/cocina.jpg'}, */
    {name: 'Electricidad', categ: '9', url: 'images/categories/electricidad.jpg'},
    {name: 'Herramientas', categ: '10', url: 'images/categories/herramientas.jpg'},
    {name: 'Pinturería', categ: '11', url: 'images/categories/pintureria.jpg'},
  ];


  private transformer = (node: CategoryNode, level: number) => {
    return {
      expandable: !!node.children && node.children.length > 0,
      name: node.name,
      id: node.id,
      level: level,
    };
  }

  treeControl = new FlatTreeControl<FlatNode>(
      node => node.level, node => node.expandable);

  treeFlattener = new MatTreeFlattener(
      this.transformer, node => node.level, node => node.expandable, node => node.children);

  dataSource = new MatTreeFlatDataSource(this.treeControl, this.treeFlattener);
 


  constructor( private _articulosService: ArticulosService, 
               private _usuarioService: UsuarioService,
               private clientesService: ClientesService, 
               private _ventasService: VentasService,
               private _presupService: PresupuestosService,
               private _excelService: ExcelService,
               private router: Router, 
               private datepipe: DatePipe,
               private categoriesService: CategoriesService) {
    this.flag = false;
  
    this.token = _usuarioService.getToken();
    
    this.identity = this._usuarioService.getIdentidad();

    this.order = new Order(null, null, null, null, [], null, null, null, null);
    this.order.fecha = this.datepipe.transform(new Date(), 'yyyy-MM-dd hh:mm:ss');
    this.order.dateTime = this.datepipe.transform(new Date(), 'yyyy-MM-ddThh:mm:ss');
    this.order.user_id = this._usuarioService.getIdentidad().sub;

    

    this.clientesService.getUserIDClients(this.token, this.identity.sub).subscribe(
      Response => {
        if (Response.status === 'success') {
          this.cliente = Response.data[0];
        } else {
          console.log(Response.message);
        }
      },
      error => {
        console.log('error: ', error);
    });

    this._ventasService.getMejoresArticulos20(this.token).subscribe(
      Response => {
        //console.log('Response: ', Response);
        if (Response.status === 'success') {
          this.calculateFinalPricesMejor(Response.data);
          this.artdataSource = Response.data;
          //console.log('mejores:',Response.data);
        }
      },
      error => {
        console.log('error: ', error);
      });
    
      this._articulosService.getArtCat(70, this.token).subscribe(
        Response => {
          if (Response.status === 'success') {
            this.herramientasdataSource = Response.articulos;
            this.calculateFinalPrices(Response.articulos);
          }
        },
        error => {
          console.log('error: ', error);
      });

      this._articulosService.getArtCat(335, this.token).subscribe(
        Response => {
          if (Response.status === 'success') {
            this.obradataSource = Response.articulos;
            this.calculateFinalPrices(Response.articulos);
          }
        },
        error => {
          console.log('error: ', error);
      })
    
    this._articulosService.countArt(this.token).subscribe(
      Response => {
        //this.cantArt = Response.cant;
        //console.log("cantart ", this.cantArt);
      },
      error => {
        console.log(error);
      }
    );
    this._articulosService.getArtPages(this.index, this.token).subscribe(
      Response => {
         console.log('constructor',Response.articulos);
         this.calculateFinalPrices(Response.articulos);        
         this.filteredArticulos = Response.articulos;
         this.cantArt= this.filteredArticulos.length;
         this.flag = true;
         this.index+=20; 
      },
      error => {
        this.flag = true;
        // console.log(error);
      });

      this.getCategories();
      
  }

  ngOnInit() { 
  }

  hasChild = (_: number, node: FlatNode) => node.expandable;

  busquedatxt(filterValue: string) {

    this._articulosService.getArtTermCatID(filterValue, 1, 999, this.token).subscribe(
      Response => {
        this.flagbusqueda = true;
        this.calculateFinalPrices(Response.articulos);
        this.filteredArticulos = Response.articulos;
        // console.log(this.filteredArticulos);
        this.cantArt = this.filteredArticulos.length;
      },
      error => {        
        console.log(error);
      });
    this.searchTerm = filterValue;
    this.p = 1;
    this.categ = 999;
    this.catestr ='';
    this.flagbuscado=true;
  }

  busquedaTxtCateg(filterValue: string){
    this._articulosService.getArtTermCatID(filterValue, 1, this.categ, this.token).subscribe(
      Response => {
        this.calculateFinalPrices(Response.articulos);
        this.filteredArticulos = Response.articulos;
       //  console.log(this.filteredArticulos);
        this.cantArt = this.filteredArticulos.length;
      },
      error => {
        console.log(error);
      });
    this.searchTerm = filterValue;
    this.p = 1;
  }

  busquedaCateg(cat: number, catestr:string) {
    this.flag = false;
    this.catestr = catestr;
    this._articulosService.getArtTermCatID( "" , 1 , cat , this.token).subscribe(
      Response => {         
         this.calculateFinalPrices(Response.articulos);
         this.filteredArticulos = Response.articulos;
         //this.filteredArticulos = this.filteredArticulos.concat(Response.articulos);
        //console.log(Response.cant);
         this.cantArt = this.filteredArticulos.length;
         this.flag = true;
         this.flagbusqueda = true;
         this.flagbuscado = true;
      },
      error => {        
        console.log(error);
      });
    this.searchTerm = "";
    this.categ = cat;
    this.p = 1;

    let scrollToTop = window.setInterval(() => {
      let pos = window.pageYOffset;
      if (pos > 0) {
          window.scrollTo(0, pos - 80); // how far to scroll on each step
      } else {
          window.clearInterval(scrollToTop);
      }
    }, 16);
  }

  
  getFinalPrice(ganancia = 0, impuesto = 0, costo = 0, flete = 0): number {          
    let x1 = ganancia/100;
    let x2 = costo;
    let x3 = flete/100;
    let x4 = impuesto/100;    

    let co = (x4* x2) + (x3 * x2) + x2;
 
    return (x1 * co)  + co;

    // return ((x3 * x2) + (x4 * x2) + x2) + (x1 * x2); 
}

calculateFinalPricesMejor(articulos) {
  for (let i = 0; i < articulos.length; i++) {
    articulos[i].precioContado = this.getFinalPrice(articulos[i].mejores.ganancia, articulos[i].mejores.impuesto, articulos[i].mejores.costo, articulos[i].mejores.flete);
    articulos[i].precioCtaCte = articulos[i].precioContado*1.3;
  }
}

calculateFinalPrices(articulos) {
  for (let i = 0; i < articulos.length; i++) {
    articulos[i].precioContado = this.getFinalPrice(articulos[i].ganancia, articulos[i].impuesto, articulos[i].costo, articulos[i].flete);
    articulos[i].precioCtaCte = articulos[i].precioContado*1.3;
  }
}

catalogo(){
  this.loading =true;
  this.pdfCatalogo = APIURL.urlPublic + 'pdfCatalogo/pdf' + + '.pdf';
  this._articulosService.armarCatalogo(this.token).subscribe(
    Response => {      
               console.log(Response);
               if (Response.status === 'success') {
                window.open(APIURL.urlPublic + 'pdfCatalogo/' + Response.pdfName, '_blank');
                this.loading =false;
               }
    },
    error =>{
      console.log(error);
      this.loading =false;
      Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
    });
}

exportAsXLSX():void {
  let artxls:any []=[];
  this.loading = true;
  this._articulosService.obtenerPreciosXLSX(this.token).subscribe(
    Response => {
      //console.log(Response.articulos);
      // this.calculateFinalPrices(Response.articulos);s
       artxls = Response.articulos;
       //console.log('are',Response.articulos);         
        this._excelService.exportAsExcelFile(artxls, 'Lista de precios');
        this.loading = false;
    },
    error => {        
      console.log(error);
      this.loading = false;
    }); 
  
}

changePage(p)
{  
  this._articulosService.getArtTermCatID(this.searchTerm, p, this.categ, this.token).subscribe(
    Response => {
      //  console.log(Response.articulos);        
       this.calculateFinalPrices(Response.articulos);
       this.filteredArticulos = Response.articulos;
       this.cantArt=this.filteredArticulos.length;
    },
    error => {
      this.flag = true;
      // console.log(error);
    });
  
  this.p = p;
  
  let scrollToTop = window.setInterval(() => {
    let pos = window.pageYOffset;
    if (pos > 0) {
        window.scrollTo(0, pos - 80); // how far to scroll on each step
    } else {
        window.clearInterval(scrollToTop);
    }
  }, 16);
}

hideCategories(){
  this.hidecat = !this.hidecat;
}

cleanAll(){
  this.flag = false;
  this.flagbusqueda = false;
  this.flagbuscado = false;
  this.p = 1;
  this.categ = 999;
  this.catestr ='';
  this.searchTerm = "";
  this._articulosService.countArt(this.token).subscribe(
    Response => {
      //this.cantArt = Response.cant;
      //console.log("cantart ", this.cantArt);
    },
    error => {
      console.log(error);
    }
  );
  this._articulosService.getArtWithID(1, this.token).subscribe(
    Response => {
       //console.log(Response.articulos);
       //this.calculateFinalPrices(Response.articulos);        
       this.filteredArticulos = Response.articulos;
       this.flag = true; 
    },
    error => {
      this.flag = true;
      // console.log(error);
    });

}

getCategories(){
  
  this.categoriesService.obtenerCateg(this.token).subscribe(
    Response => {
      if (Response.status === 'success') {
        this.categories = Response.children;
        this.dataSource.data = JSON.parse(this.categories);
        //console.log('datasource ', this.dataSource);
      } else {
        console.log(Response.message);
      }
    },
    error => {
      console.log('error: ', error);
    });
}

//Funciones Slider

slideConfig = {
  "slidesToShow": 6,
  "slidesToScroll": 1,
  "autoplay": true,
  "autoplaySpeed": 1500,
  "infinite": true
};

slideConfig2 = {
  "slidesToShow": 5,
  "slidesToScroll": 2,
  "autoplay": true,
  "autoplaySpeed": 1700,
  "infinite": true
};

slickInit(e:any) {
  //console.log('slick initialized');
}

breakpoint(e:any) {
  //console.log('breakpoint');
}

afterChange(e:any) {
  //console.log('afterChange');
}

beforeChange(e:any) {
  //console.log('beforeChange');
}

addToCart(codArtiCart, precio){

  this._articulosService.getArticulo(this.token, codArtiCart).subscribe(
    Response => {
      if (Response.status === 'success') {
        this.articulo = Response.articulo;
      } else {
        Swal.fire('Error', Response.message, 'error');
      }  
    },
    error => {
      console.log(error);
      Swal.fire('Error', '!Oops Algo salio mal', 'error');
  });


  this._usuarioService.getUser(this.identity.sub, this.token).subscribe(
    Response => {
      this.flagpresupuesto = Response.user.id_presupuesto;
      //console.log("presupuesto:",this.flagpresupuesto);
      //Tengo un presupuesto asociado - Añado artículos
      if(this.flagpresupuesto != null ){
        //console.log('Hay presupuesto asignado');
        this._presupService.getPresupuesto(this.token, this.flagpresupuesto).subscribe(
          Response => {
            if (Response.status === 'success') {
              this.auxOrder = Response.order;
              this.dataSourceSearchOrder = new MatTableDataSource(this.auxOrder);
              this.dataSourceSearchOrder.paginator = this.paginator;
              this.dataSourceSearchOrder.sort = this.sort;
              this.appendPresupuestoLine(this.dataSourceSearchOrder.data, precio);
            } 
          },
          error => {
            console.log(error);
          });  

      }
      //No tengo un presupuesto asociado - Lo creo y cargo artículos al carro
      else{
        //console.log('No hay presupuesto asignado');
        this.generarPresupuesto(precio);
      }    
    },
    error => {
      console.log(error);
    });
}


generarPresupuesto(precio){
  this.order.id_cliente = this.cliente.codCliente;
  this.order.total = (precio);
  this.order.discount =  0;
  this.order.clientDiscount = 0;
  this.order.discountC = 0;
  this.order.discountR = 0;
  this.order.rechargeCC = 0;
  this.order.discountCant = 0;
  this.order.discountCards = 0;
  this.order.establishment_id = 1;
  this.order.selectedClient = this.cliente;
  this.order.orderLine.push(this.articulo);
  this.order.orderLine[0].unitPrice = precio;
  this.order.orderLine[0].id_articulo = this.articulo.codArticulo;
  this.order.orderLine[0].cantidad = 1;
  this.order.orderLine[0].total = precio;        
  //console.log('order:',this.order);

  this._presupService.armarPresupuestosinPDF(this.token, this.order).subscribe(
  Response => {
           console.log(Response);
           if (Response.status === 'success') {
              this.identity.idpresup = Response.idPres;
              this._usuarioService.addPresup(this.identity.sub, this.identity.idpresup, this.token).subscribe(
                Response => {
                  console.log(Response);
                },
                error => {
                  console.log(error);
                });

              Swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'El artículo fue añadido a su carro de compras',
                showConfirmButton: false,
                timer: 1500
              });
           }
  },
  error =>{
    console.log(error);
    Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
  });
}


appendPresupuestoLine(presupuesto, precio){
  //console.log('lalala',presupuesto);
  //this.index = presupuesto.orderLines.length ;

  this.order.orderLine.push(this.articulo);
  this.order.orderLine[0].unitPrice = precio;
  this.order.orderLine[0].id_articulo = this.articulo.codArticulo;
  this.order.orderLine[0].cantidad = 1;
  this.order.orderLine[0].total = precio; 
  //console.log('llerolero',this.order);
  this._presupService.addOrderLine(this.token, presupuesto.id, this.order.orderLine).subscribe(
  Response => {
           console.log(Response);
           if (Response.status === 'success') {
              
              Swal.fire({
                position: 'top-end',
                type: 'success',
                title: 'El artículo fue añadido a su carro de compras',
                showConfirmButton: false,
                timer: 1500
              });
           }
  },
  error =>{
    console.log(error);
    Swal.fire('Error', 'Ups! Algo salio mal!', 'error');
  });
}

loadMore(){
  this._articulosService.getArtPages(this.index, this.token).subscribe(
  Response => {
    //console.log('loadmore',Response.articulos);
    this.calculateFinalPrices(Response.articulos);
    Response.articulos.forEach(element => {
      this.filteredArticulos.push(element);
    });        
    //this.filteredArticulos.push(Response.articulos);
    //console.log('traidos',this.filteredArticulos);
    this.index +=20; 
  },
  error => {
    console.log(error);
  }); 
}



}
