export class Recibo {
    public id: number;    
    public cliente_id: number;
    public amount: number;    
    public user_id: number;
    public aprobed_id: number;
    public date: string;
    public state: number;
    public observation_state: string;
    public observation_pay: string;

    constructor(id: number, cliente_id: number, amount: number, user_id: number, aprobed_id: number, date: string, state: number,
                observation_state: string, observation_pay: string) {
        this.id = id;        
        this.cliente_id = cliente_id;
        this.amount = amount;        
        this.user_id = user_id;
        this.aprobed_id = aprobed_id;
        this.date = date;
        this.state = state;
        this.observation_state = observation_state;
        this.observation_pay = observation_pay;
    }
}