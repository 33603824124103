import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'newPercent'
})
export class NewPercentPipe implements PipeTransform {

  transform(text: string): string {
    return text + '%';
  }

}
