import { Component, OnInit, HostListener, ViewChild, ElementRef } from '@angular/core';
import { Vendedor } from '../../../models/vendedor';
import { MatTableDataSource } from '@angular/material/table';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { APIURL } from '../../../services/apiUrl';
import { VendedoresService } from '../../../services/vendedores/vendedores.service';
import Swal from 'sweetalert2';
import { ProveedoresService } from '../../../services/proveedores/proveedores.service';
import { MatSort, MatSortable, Sort } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { MatDialog } from '@angular/material/dialog';
import { DetalleCategoryComponent } from './detalle-category/detalle-category.component';

@Component({
  selector: 'app-dashboard-vendedores',
  templateUrl: './dashboard-vendedores.component.html',
  styleUrls: ['./dashboard-vendedores.component.css']
})
export class DashboardVendedoresComponent implements OnInit {
  
  sort: any;
  @ViewChild(MatSort, { static: false }) set content(content: ElementRef) {
    this.sort = content;
    if (this.sort) {
      this.dataSource.sort = this.sort;
   }
  }
  identidad: any;
  vendedores: Vendedor[];
  selectedVend: any = null;
  token: string;
  flag: boolean = false;
  loading:boolean = false;
  url:string;
  clients: number[]=[];
  categories: any[];
  orders: any[];
  dataSource = new MatTableDataSource<any>([]);
  displayedColumns: string[] = ['cliente','calif_pago','objetivo','total'];

  constructor(private _userService: UsuarioService, private _proveedoresService: ProveedoresService, 
    private _vendedoresService: VendedoresService, private elementRef: ElementRef, public dialog: MatDialog,) {
    this.token = _userService.getToken();
    this.url = APIURL.urlPublic;
    this.identidad = _userService.getIdentidad();

    if (this.identidad.rol === 'admin' || this.identidad.rol === 'administrativo') {
        this._vendedoresService.getSellers(this.token).subscribe(
          Response => {
            //console.log(Response);
            if (Response.status === 'success') {
                let vend = new Vendedor('Sin Vendedor',23,23,'','',[],0);
                this.vendedores = Response.vendedores;
                this.vendedores.push(vend);
                this.flag = true;
            } else {
              Swal.fire('Error', Response.message, 'error');
            }
          },
          error => {
            console.log('error: ', error);
          });
        } else if (this.identidad.rol === 'vendedor') {
  
          this._vendedoresService.getSellerUser(this.identidad.sub, this.token).subscribe(
            Response => {
              this.selectedVend = Response.data[0];
              this.cargarDatosVen();
            },
            error => {
              console.log('error: ', error);
            });   
         this._proveedoresService.getStatsVendedor(this.token, this.identidad.sub).subscribe(
                  Response => {
                    if (Response.status === 'success') {
                      this.clients = Response.clientes;
                      this.categories = Response.categories;
                      //console.log('clientes', this.clients);
                      this.loading = false;
                      this.flag = true;
                    }
                  },
                  error => {
                    console.log(error);
                  });
        }
   }

  ngOnInit() {
  }

  applyFilter(filterValue: string) {
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return  data[0].nombre.toLowerCase().includes(filter);    
  };
  }

  cargarDatosVen() {

    if(this.selectedVend.id != 13){
      this.loading = true;      
      this._proveedoresService.getStatsVendedor(this.token, this.selectedVend.id).subscribe(
        Response => {
          if (Response.status === 'success') {
   
            this.orders = Response.oline;
            this.orders.forEach(or => {
              let aux:any[];
              aux = or;
              for (let i = 10; i >= 0; i--) {
                if((typeof or[i].status !== "undefined") && (i != or[i].status-1)){
                  or[or[i].status-1].totalgru = new Number(or[i].totalgru);
                  or[i].totalgru = 0;
                }
              }
            or = aux;
            });
            this.categories = Response.categories;
            this.categories.forEach(element => {
              this.displayedColumns.push(element.category);
            });
 
         this.dataSource = new MatTableDataSource(this.orders);
         this.dataSource.sort = this.sort;
         this.flag = true;

        this.loading = false;
          }
        },
        error => {
          console.log(error);
        });
    }
    else{
     
    }
  }

  getTotalCost(j:number){
    let total:number = 0;
      this.orders.forEach(el => {
        total += el[j].totalgru;
      });
      return total;
  }

  getTotalClient(el:any){
    let total:number = 0;
    for (let i = 0; i < 11; i++) { 
      total += el[i].totalgru;
     }    
    return total;
  }


  sortData(sort: Sort) {
    
    const data = this.orders.slice();
    if (!sort.active || sort.direction === '') {
      this.orders = data;
      return;
    }
    else
      this.dataSource = new MatTableDataSource<any>([]);

    this.orders = data.sort((a, b) => {
      const isAsc = sort.direction === 'asc';
      switch (sort.active) {
        case 'cliente': return this.compare(a[0].nombre, b[0].nombre, isAsc);
        case 'objetivo': return this.compare(a[0].obj_mensual, b[0].obj_mensual, isAsc);
        case 'calif_pago': return this.compare(a[0].calif_pago, b[0].calif_pago, isAsc);
        case 'Construccion tradicional': return this.compare(a[0].totalgru, b[0].totalgru, isAsc);
        case 'Construccion en seco': return this.compare(a[1].totalgru, b[1].totalgru, isAsc);
        case 'Metalurgica': return this.compare(a[2].totalgru, b[2].totalgru, isAsc);
        case 'Aislantes y membranas': return this.compare(a[3].totalgru, b[3].totalgru, isAsc);
        case 'Ferreteria': return this.compare(a[4].totalgru, b[4].totalgru, isAsc);
        case 'Plomeria': return this.compare(a[5].totalgru, b[5].totalgru, isAsc);
        case 'Sanitarios': return this.compare(a[6].totalgru, b[6].totalgru, isAsc);
        case 'Cocina': return this.compare(a[7].totalgru, b[7].totalgru, isAsc);
        case 'Electricidad': return this.compare(a[8].totalgru, b[8].totalgru, isAsc);
        case 'Herramientas': return this.compare(a[9].totalgru, b[9].totalgru, isAsc);
        case 'Pintureria': return this.compare(a[10].totalgru, b[10].totalgru, isAsc);
        default: return 0;
      }
    });
    
    this.dataSource = new MatTableDataSource(this.orders);       
    

  }


compare(a: number | string, b: number | string, isAsc: boolean) {
  return (a < b ? -1 : 1) * (isAsc ? 1 : -1);
}


verDetalle(categ:number,cli:number): void {
  const dialogRef = this.dialog.open(DetalleCategoryComponent, {
    width: 'auto',
    data: {
        cat: categ,
        cli: cli
    }
  });
}

 

}
