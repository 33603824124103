import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UsuarioService } from 'src/app/services/services.index';
import Swal from 'sweetalert2';
import { Usuario } from '../../../models/usuario';
import { Router } from '@angular/router';

@Component({
  selector: 'app-editar-usuario',
  templateUrl: './editar-usuario.component.html',
  styleUrls: ['./editar-usuario.component.css']
})

export class EditarUsuarioComponent implements OnInit {

  usuario: any;
  token: string;
  identidad: Usuario;
  pass1: string;
  pass2: string;

  constructor(private _userService: UsuarioService, public router: Router, ) {
    this.usuario = _userService.getIdentidad();
    console.log('usuario: ', this.usuario);
    this.token = _userService.getToken();
  }

  ngOnInit() {
  }

  changePassword(){
 
    if(this.pass1==this.pass2 && this.pass1 && this.pass2){
      
      this._userService.changePass(this.usuario.email, this.pass1, this.token).subscribe(
       Response => {
        console.log(Response);
        if (Response.status === 'success') {
          Swal.fire('Exito', 'Su contraseña fue cambiada con exito, reingrese al sistema para continuar', 'success');
          localStorage.removeItem('token');
          localStorage.removeItem('identidad');
          this.router.navigate(['/login']);
        } else {
          Swal.fire('Cuidado!', 'Hubo un error al modificar su contraseña', 'warning');
          console.log(Response.message);
          }
        },
       error => {
          console.log('error: ', error);
      });
    }
    else {
      Swal.fire('Cuidado!', 'Verifique los datos ingresados', 'warning');
    }
  }

}
