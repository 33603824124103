import { Component, OnInit, Input } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-mat-table-generic',
  templateUrl: './mat-table-generic.component.html',
  styleUrls: ['./mat-table-generic.component.css']
})
export class MatTableGenericComponent implements OnInit {

  @Input() dataShow: any[];

  @Input() displayedColumns: string[];

  @Input() displayNames: string[];

  @Input() currency: boolean[];

  @Input() date: boolean[];

  dataSource = new MatTableDataSource<any>([]);

  test = true;
  optionDisplay: number = 0;

  constructor() { }

  ngOnInit() {

    // INICIALIZAR DATOS
    this.dataSource = new MatTableDataSource(this.dataShow);
  }

  getElement(index: number, column) {
      if (this.currency[index]) {
        this.optionDisplay = 1;
        return this.dataSource.data[index][column];
      } else {
        return this.dataSource.data[index][column];
      }
  }

}
