import { Cliente } from './clientes';
export class Vendedor {

    public nya: string;
    public dni: number;
    public tel: number;
    public domicilio: string;
    public mail: string;
    public clients: Cliente[];
    public user_id: number;

    constructor(nya: string, dni: number, tel: number, domicilio: string, mail: string, clients: Cliente[], sub: number) {

        this.nya = nya;
        this.dni = dni;
        this.tel = tel;
        this.domicilio = domicilio;
        this.mail = mail;
        this.clients = clients;
        this.user_id = sub;
    }
}
