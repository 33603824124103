import { Component, OnInit, ViewChild, ElementRef } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';
import { MatSort, MatSortable } from '@angular/material/sort';
import { MatPaginator } from '@angular/material/paginator';
import { Usuario } from '../../../models/usuario';
import { UsuarioService } from '../../../services/usuario/usuario.service';
import { VentasService } from '../../../services/ventas/ventas.service';
import { Router } from '@angular/router';
import { APIURL } from '../../../services/apiUrl';
import Swal from 'sweetalert2';
import { ClientesService } from '../../../services/clientes/clientes.service';
import { Cliente } from '../../../models/clientes';
import { VendedoresService } from '../../../services/vendedores/vendedores.service';
import { Vendedor } from '../../../models/vendedor';

@Component({
  selector: 'app-pedidos',
  templateUrl: './pedidos.component.html',
  styleUrls: ['./pedidos.component.css']
})
export class PedidosComponent implements OnInit {
  @ViewChild(MatSort, { static: true }) sort: MatSort;
  @ViewChild(MatPaginator, { static: true }) paginator: MatPaginator;
  @ViewChild('matTable', { static: false }) elementView: ElementRef;
  itemPerPage = 20;
  position = 0;
  limitArray = 0;
  yOffset = 400;
  ordersChunk: any[] = [];
  flag = false;
  Orders: any = [];
  token: string;
  identidad: any;
  dataSource = new MatTableDataSource<any>([]);
  loading:boolean = false;
  displayedColumns: string[] = ['id', 'user', 'created_at','isOpen', 'total', 'acciones'];
  uncliente: Cliente;
  checkCanceladas: boolean;
  checkPendientes: boolean = true;
  checkTodas: boolean;
  
  constructor( _userService: UsuarioService, private _ventasService: VentasService, private _vendededoresService: VendedoresService,
    private router: Router, private elementRef: ElementRef, private _clientesService: ClientesService) {
    this.token = _userService.getToken();
  // console.log('sort',this.sort);
    this.identidad = _userService.getIdentidad();
    if (this.identidad.rol === 'clientedc' || this.identidad.rol === 'clientetom') {
      this._clientesService.getUserIDClients(this.token, this.identidad.sub).subscribe(
          Response => {
            if (Response.status === 'success') {
              this.uncliente = Response.data[0];
              _ventasService.getPedidosCliente(this.token,0,this.uncliente.codCliente).subscribe(
                Response => {
                  this.Orders = Response.orders;
                  this.flag = true;

                  this.dataSource = new MatTableDataSource(this.Orders);                 
                  this.dataSource.paginator = this.paginator;
                  this.dataSource.sort = this.sort;
                  
                },
                error => {
                  this.flag = true;
                  console.log(error);
                });


              this.flag = true;
            }
          },
          error => {
            console.log('error: ', error);
          });
        }
        else if(this.identidad.rol === 'vendedor'){
          this.displayedColumns.splice(1,0,'cliente');
                _ventasService.getPedidosVendedor(this.token,0,this.identidad.sub).subscribe(
                  Response => {
                    this.Orders = Response.orders;
                    //console.log('ven',this.Orders);
                                    
                    this.dataSource = new MatTableDataSource(this.Orders);
                    // this.dataSource.sortingDataAccessor = (item, property) => {
                    //   switch (property) {
                    //     case 'cliente': return item.cliente.nombre;
                    //     default: return item[property];
                    //   }
                    // };
                    this.dataSource.sortingDataAccessor = (item, property) => {
                      switch (property) {
                        case 'user': return item.user.nya;
                        default: return item[property];
                      }
                    };
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    this.flag = true;   
                  },
                  error => {
                    this.flag = true;
                    console.log(error);
                  });
  
                                    

        }
        else{
          this.displayedColumns.splice(1,0,'cliente');
      _ventasService.getPedidos(this.token,0).subscribe(
        Response => {
          this.Orders = Response.orders;
          this.flag = true;

          this.dataSource = new MatTableDataSource(this.Orders);
          // this.dataSource.sortingDataAccessor = (item, property) => {
          //   switch (property) {
          //     case 'cliente': return item.cliente.nombre;
          //     default: return item[property];
          //   }
          // };
          this.dataSource.sortingDataAccessor = (item, property) => {
            switch (property) {
              case 'user': return item.user.nya;
              default: return item[property];
            }
          };
          this.dataSource.paginator = this.paginator;
          this.dataSource.sort = this.sort;
        },
        error => {
          this.flag = true;
          console.log(error);
        });
      }
      console.log('sort',this.sort);
   }

  
  ngOnInit() {
    
  }

  applyFilter(filterValue: string) {     
    this.dataSource.filter = filterValue.trim().toLowerCase();
    this.dataSource.filterPredicate = function(data, filter: string): boolean {
      return data.created_at.toLowerCase().includes(filter) ||
      data.id.toString().includes(filter) ||
      data.nombre.toLowerCase().includes(filter) ||
      data.user.nya.toLowerCase().includes(filter) ||
      data.total.toString().includes(filter);
  };     

}

viewPresupuesto(id: number,est:number) {
 
  if (est == 1) {    
      window.open(APIURL.urlPublic + 'pdfPresupuesto/' + id + 'pres.pdf', '_blank');
  } else
      window.open(APIURL.urlPublic + 'pdfPresupuestoTOM/'  + id + 'prestom.pdf', '_blank');
               
}

cancelOrder(idOrder: number, isOpen: number){
  if (isOpen != null) {
    Swal.fire(
      {
        title: 'Estas Seguro?',
        type: 'warning',
        text: 'Se anulará por completo',
        showConfirmButton: true,
        confirmButtonColor: '#d33',
        confirmButtonText: 'Confirmar',
        showCancelButton: true,
      }
    ).then ((result) => {
       if (result.value) {
          this._ventasService.cancelPedido(this.token, idOrder).subscribe(
        Response => {
          // console.log(Response);
          if (Response.status === 'success') {
              let aux = Response.order;
              for (let i = 0; i < this.dataSource.data.length; i++) {
                if (this.dataSource.data[i].id === idOrder) {
                      this.dataSource.data[i].isOpen = null;
                      this.dataSource._updateChangeSubscription();
                }
              }
              Swal.fire('Exito', 'El pedido fue anulado con éxito', 'success');                          
          } else {
            Swal.fire('Error', Response.message, 'error');
          }
        },
        error => {
        console.log(error);
        Swal.fire('Error', 'Algo salio mal!', 'error');
        });
       }
    });
  } else {
    Swal.fire('Error', 'Este pedido ya esta anulado', 'error');
  }
}


statusCheckPendientes() {
  if (this.checkPendientes) {
      this.checkCanceladas = false;
      this.checkTodas = false;
  }
  this.clearDataTable();
  this.getInvoices(0);
}

statusCheckCanceladas() {
  if (this.checkCanceladas) {
    this.checkPendientes = false;
    this.checkTodas = false;
  }
  this.clearDataTable();
  this.getInvoices(1);
}

statusCheckTodas() {
  if (this.checkTodas) {
    this.checkPendientes = false;
    this.checkCanceladas = false;
  }
  this.clearDataTable();
  this.getInvoices(2);
}

getInvoices(op){

  if (this.identidad.rol === 'clientedc' || this.identidad.rol === 'clientetom') {
    this._clientesService.getUserIDClients(this.token, this.identidad.sub).subscribe(
        Response => {
          if (Response.status === 'success') {
            this.uncliente = Response.data[0];
            this._ventasService.getPedidosCliente(this.token,op,this.uncliente.codCliente).subscribe(
              Response => {
                this.Orders = Response.orders;
                this.flag = true;
      
                this.dataSource = new MatTableDataSource(this.Orders);             
                this.dataSource.paginator = this.paginator;
                this.dataSource.sort = this.sort;
              },
              error => {
                this.flag = true;
                console.log(error);
              });

            this.flag = true;
          }
        },
        error => {
          console.log('error: ', error);
        });
      }
       else if(this.identidad.rol === 'vendedor'){

          
                this._ventasService.getPedidosVendedor(this.token,op,this.identidad.sub).subscribe(
                  Response => {
                    this.Orders = Response.orders;
                    console.log('presucl',this.Orders);
                    this.flag = true;
                    this.dataSource = new MatTableDataSource(this.Orders);
                    this.dataSource.sortingDataAccessor = (item, property) => {
                      switch (property) {
                        case 'cliente': return item.cliente.nombre;
                        default: return item[property];
                      }
                    };
                    this.dataSource.sortingDataAccessor = (item, property) => {
                      switch (property) {
                        case 'user': return item.user.nya;
                        default: return item[property];
                      }
                    };
                    this.dataSource.paginator = this.paginator;
                    this.dataSource.sort = this.sort;
                    console.log('datas',this.dataSource);
                  },
                  error => {
                    this.flag = true;
                    console.log(error);
                  });

        }
      else{
       
    this._ventasService.getPedidos(this.token,op).subscribe(
      Response => {
        this.Orders = Response.orders;
        this.flag = true;

        this.dataSource = new MatTableDataSource(this.Orders);
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'cliente': return item.cliente.nombre;
            default: return item[property];
          }
        };
        this.dataSource.sortingDataAccessor = (item, property) => {
          switch (property) {
            case 'user': return item.user.nya;
            default: return item[property];
          }
        };
        this.dataSource.paginator = this.paginator;
        this.dataSource.sort = this.sort;
      },
      error => {
        this.flag = true;
        console.log(error);
      });
    } 
    console.log('sort',this.sort);
}

clearDataTable() {
  this.dataSource.data = [];
  }
}
