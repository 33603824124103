import { Component, OnInit } from '@angular/core';
import { UsuarioService } from '../services/services.index';
import { Usuario } from '../models/usuario';
import { Router } from '@angular/router';
import { RoutingStateService } from '../services/routingState/routing-state.service';

declare function init_plugins();  // llamar funcion de javascript fuera de angular 1

@Component({
  selector: 'app-pages',
  templateUrl: './pages.component.html',
  styles: []
})
export class PagesComponent implements OnInit {
  
  identidad: any;
  clos:boolean =true;

  constructor(public router: Router, private _usuarioService: UsuarioService, private routingStateService: RoutingStateService) {
    let usuario: Usuario = _usuarioService.getIdentidad();
    console.log(usuario.rol);
    this.routingStateService.loadRouting();
    this.identidad = _usuarioService.getIdentidad();
    if (usuario == null) {
      this.router.navigate(['']);
    
    }

   }

  ngOnInit() {

    init_plugins(); // 2
  }

  get isAdmin() {
    if(this.identidad.rol == 'clientetom' || this.identidad.rol == 'clientedc' )
      return false;
    else
      return true;
  }

  close(){
    this.clos = false;
  }

}
