import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders} from '@angular/common/http';
import { APIURL } from '../apiUrl';
import { stringify } from 'querystring';
import { Observable } from 'rxjs';
import saveAs from 'file-saver';

@Injectable({
  providedIn: 'root'
})
export class CargaImagenService {
  url: string;
  constructor(private _httpClient: HttpClient) {
    this.url = APIURL.url;
   }

  subirImagen(imagen: File): Observable<any> {
    const formData = new FormData();

    formData.append('imagenPropia', imagen, imagen.name);

    return this._httpClient.post(this.url + 'imagenArticulo', formData);
  }

  storeImageBase64(imagen: File, path: string, imageName: string): Observable<any> {
    const formData = new FormData();

    formData.append('imagenPropia', imagen, imagen.name);

    return this._httpClient.post(this.url + 'storeImageBase64/' + path + '/' + imageName, formData);
  }

  deleteImage(path: string): Observable<any> {

    let json = JSON.stringify(path);
    let params = 'json=' + json;

    let headers = new HttpHeaders().set('Content-type', 'application/x-www-form-urlencoded');

  return this._httpClient.post(this.url + 'deleteImage', params , {headers: headers});
  }

  subirPdf(pdf: File): Observable<any> {
    const formData = new FormData();

    formData.append('pdf', pdf);

    return this._httpClient.post(this.url + 'pdf', formData);
  }

  downloadDoc(url: string): Observable<any> {
    let headers = new HttpHeaders();
    headers = headers.set('Accept', 'application/pdf');
    return this._httpClient.get(url, { headers: headers, responseType: 'arraybuffer' });
}

}
