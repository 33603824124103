// Modulos
import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

// Componentes
import { AutocompleteChipsComponent } from './autocomplete-chips/autocomplete-chips.component';
import { MatTableGenericComponent } from './mat-table/mat-table-generic.component';
import { AddCommentComponent } from './add-comment/add-comment.component';
import { SnackbarComponent } from './snackbar/snackbar.component';


@NgModule({
  imports: [
    CommonModule,
    AngularMaterialModule,
    ReactiveFormsModule,
    FormsModule
  ],
  declarations: [
    AutocompleteChipsComponent,
    MatTableGenericComponent,
    AddCommentComponent,
    SnackbarComponent
  ],
  exports: [
    AutocompleteChipsComponent,
    MatTableGenericComponent,
    AddCommentComponent
  ]
})
export class ComponentsModule { }
