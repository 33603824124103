import { NgModule } from '@angular/core';
import { DashboardComponent } from './dashboard/dashboard.component';
import { ProgressComponent } from './progress/progress.component';
import { Graficas1Component } from './graficas1/graficas1.component';
import { PagesComponent } from './pages.component';
import {NgSelectModule} from '@ng-select/ng-select';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
// tslint:disable-next-line:max-line-length

// import { MomentDateModule, MomentDateAdapter } from '@angular/material-moment-adapter';

// create our cost var with the information about the format that we want
// export const MY_FORMATS = {
//     parse: {
//       dateInput: 'DD/MM/YYYY',
//     },
//     display: {
//       dateInput: 'DD/MM/YYYY',
//       monthYearLabel: 'MM YYYY',
//       dateA11yLabel: 'DD/MM/YYYY',
//       monthYearA11yLabel: 'MM YYYY',
//     },
//   };

// Modules
import { SharedModule } from '../shared/shared.module';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { StickyModule } from 'ng2-sticky-kit';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { ComponentsModule } from '../components/components.module';
import { AngularMaterialModule } from '../angular-material/angular-material.module';
import { ScrollingModule } from '@angular/cdk/scrolling';
import {NgxPaginationModule} from 'ngx-pagination';
import { FilterPipeModule } from 'ngx-filter-pipe';
// Graficos
import { ChartsModule } from 'ng2-charts';

// Rutas
import { PAGES_ROUTES } from './pages.routes';
// Pipes
import { NewPercentPipe } from '../pipes/new-percent.pipe';
import { NoimagePipe } from '../pipes/noimage.pipe';
import { ThousandsPipe } from '../pipes/thousands.pipe';
// temporal
import { IncrementadorComponent } from '../components/incrementador/incrementador.component';

// tslint:disable-next-line:quotemark
import { DataTableModule } from "angular-6-datatable";
import { ObservablesComponent } from './observables/observables/observables.component';
import { DomseguroPipe } from '../pipes/domseguro.pipe';
import { LoadingComponent } from './loading/loading.component';

import { AddCommentComponent } from '../components/add-comment/add-comment.component';
import { CtaCteComponent } from './cobranza/cta-cte/cta-cte.component';
import { OfertasComponent } from './ofertas/ofertas.component';
import { UsuariosComponent } from './usuarios/usuarios.component';
import { EditarUsuarioComponent } from './usuarios/editar-usuario/editar-usuario.component';
import { EditarPerfilComponent } from './usuarios/editar-perfil/editar-perfil.component';
import { VisitasComponent } from './cobranza/visitas/visitas.component';
import { ReportesComponent } from './cobranza/reportes/reportes.component';
import { DashboardVendedoresComponent } from './cobranza/dashboard-vendedores/dashboard-vendedores.component';


import { CardsArticulosComponent } from './Inventario/cards-articulos/cards-articulos.component';
import { PresupuestosComponent } from './ventas/presupuestos/presupuestos.component';
import { CardsArticulosFilteredComponent } from './Inventario/cards-articulos-filtered/cards-articulos-filtered.component';
import { CartComponent } from './ventas/cart/cart.component';
import { CardsArticulosDetalleComponent } from './Inventario/cards-articulos-detalle/cards-articulos-detalle.component';
import { SlickCarouselModule } from 'ngx-slick-carousel';
import { SnackbarComponent } from '../components/snackbar/snackbar.component';
import { Ng5SliderModule } from 'ng5-slider';
import { HttpClientModule } from '@angular/common/http';
import { DetalleCategoryComponent } from './cobranza/dashboard-vendedores/detalle-category/detalle-category.component';
import { DetalleComponent } from './cobranza/reportes/detalle/detalle.component';
import { StepPuntoComponent } from './ventas/presupuestos/step-punto/step-punto.component';
import { PedidosComponent } from './ventas/pedidos/pedidos.component';
import { NgProgressModule } from '@ngx-progressbar/core';
import { NgProgressHttpModule } from '@ngx-progressbar/http';
import { CobrarComponent } from './cobranza/cobrar/cobrar.component';
import { VenderComponent } from './ventas/vender/vender.component';
import { MostrarVentasComponent } from './ventas/mostrar-ventas/mostrar-ventas.component';


@NgModule({

    
    // providers: [
    //     {provide: MAT_DATE_LOCALE, useValue: 'it'},
    //     {provide: DateAdapter, useClass: MomentDateAdapter, deps: [MAT_DATE_LOCALE]},
    //     {provide: MAT_DATE_FORMATS, useValue: MY_FORMATS}
    // ],
    declarations: [
        PagesComponent,
        DashboardComponent,
        ProgressComponent,
        Graficas1Component,
        IncrementadorComponent,
        NoimagePipe,
        ObservablesComponent,
        DomseguroPipe,
        LoadingComponent,
        NewPercentPipe,
        ThousandsPipe,
        OfertasComponent,
        CobrarComponent,
        CtaCteComponent,
        VisitasComponent,
        ReportesComponent,
        DashboardVendedoresComponent,
        UsuariosComponent,
        EditarUsuarioComponent,
        EditarPerfilComponent,
        CardsArticulosComponent,
        CardsArticulosDetalleComponent,
        PresupuestosComponent,
        CartComponent,
        CardsArticulosFilteredComponent,
        DetalleCategoryComponent,
        DetalleComponent,
        StepPuntoComponent,
        PedidosComponent,
        VenderComponent,
        MostrarVentasComponent
    ],
    exports: [
        PagesComponent,
        DashboardComponent,
        ProgressComponent,
        Graficas1Component
    ],
    imports: [
        AngularMaterialModule,
        SharedModule,
        PAGES_ROUTES,
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        NgSelectModule,
        DataTableModule,
        BrowserAnimationsModule,
        StickyModule,
        PdfViewerModule,
        ComponentsModule,
        ChartsModule,
        ScrollingModule,
        NgxPaginationModule,
        FilterPipeModule,
        Ng5SliderModule,
        SlickCarouselModule,
        HttpClientModule,
        NgProgressModule,
        NgProgressHttpModule
    ],
    entryComponents: [
        AddCommentComponent,
        SnackbarComponent
    ]

})

export class PagesModule { }
