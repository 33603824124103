import { Component, OnInit } from '@angular/core';
import { Router, ActivationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';
import { Title } from '@angular/platform-browser';

// Servicios
import { RoutingStateService } from '../../services/routingState/routing-state.service';


@Component({
  selector: 'app-breadcrumbs',
  templateUrl: './breadcrumbs.component.html',
  styles: []
})
export class BreadcrumbsComponent implements OnInit {

  titulo: string;
  arrowBack: string;

  constructor( private router: Router, private title: Title, private routingStateService: RoutingStateService ) {
   this.getDataRoute().subscribe( data => {
      this.titulo = data.titulo;
      this.title.setTitle( this.titulo );
      this.arrowBack = this.routingStateService.getPreviousUrl();
    });

  }

  ngOnInit() {
  }

  getDataRoute() {
    return  this.router.events.pipe(

      filter( evento => evento instanceof ActivationEnd ),
      filter( (evento: ActivationEnd ) => evento.snapshot.firstChild == null ),
      map( (evento: ActivationEnd) => evento.snapshot.data )

    );
  }

}
